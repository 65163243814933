import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConstants } from 'src/app/shared/constants/api-urls';
import { UrlConstants } from 'src/app/shared/constants/image-urls';
import { ApiService } from 'src/app/shared/services/common/api.service';
import { CommonService } from 'src/app/shared/services/common/common.service';
import * as firebase from 'firebase/app';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-moments-wall',
  templateUrl: './moments-wall.component.html',
  styleUrls: ['./moments-wall.component.scss']
})
export class MomentsWallComponent implements OnInit {
  @Output() goToPreviewPage = new EventEmitter();
  @Output() goToHomePage = new EventEmitter();

  logoImagePath = UrlConstants.URL.logoURL;
  certificateImagePath = UrlConstants.URL.backgroundURL;
  blackEyeImagePath = UrlConstants.URL.blackEyeURL;
  whiteEyeImagePath = UrlConstants.URL.whiteEyeURL;
  notifyEyeImagePath = UrlConstants.URL.notifyEyeURL;
  quotesImagePath = UrlConstants.URL.quoteSignURL;
  momentsImagePath = UrlConstants.URL.momentsWallURL;
  manageImagePath = UrlConstants.URL.manageRelativesURL;
  showCertificatePopup = false;
  showManagePopup = false;
  showBagfullPopup = false;
  milestoneDataResult;
  milestonesPublished: any[] = [];
  milestonesNotPublished: any[] = [];
  popupData;
  userData;
  relativesList: any;
  selectedCardDetails;
  showLoader = false;
  milestonesPublishedInitial = [];
  changeDetected = false;
  disablePublish = true;
  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private toastr: ToastrService,
    public intercom: Intercom
  ) { }

  ngOnInit() {
    this.intercom.startTour(197109);
    this.userData = JSON.parse(sessionStorage.getItem('user_data'));
    this.commonService.getFriendsAndFamilyCount.subscribe(res => {
      if (res) {
        this.getFriendsFamily();
      }
    });
    this.getUserMilestoneData();
    this.getFriendsFamily();
  }

  /**
   * @description Fetch existing friends and family details in the F&F group
   */
  getFriendsFamily() {
    const url = ApiUrlConstants.URL.getRelativesListURL + '?emailId=' + this.userData?.email;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.relativesList = response.data.friends_family;
        if (this.relativesList.length < 1 && !response.data.addLater) {
          this.showManagePopup = true;
        }
        this.showLoader = false;
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Fetch milestone data of the logged in user
   */
  getUserMilestoneData() {
    this.showLoader = true;
    const url = ApiUrlConstants.URL.getUserMilestoneData + '?userId=' + this.userData?.email;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.milestoneDataResult = response.data.milestones;
        this.milestoneDataResult.forEach(element => {
          element.card_title = element.card_description.replace(/(<([^>]+)>)/gi, '');
        });
        if (this.milestoneDataResult.length === 0) {
          this.disablePublish = true;
        } else {
          this.disablePublish = false;
        }
        sessionStorage.setItem('skin-color', response.data.skin_class);
        this.sortByAchievementDate();
        this.getPublishedMilestones();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Sort milestones in the order of latest achievements
   */
  sortByAchievementDate(): void {
    this.milestoneDataResult.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());
  }

  /**
   * @description Identify the selected milestone
   */
  getSelectedCard(list) {
    let selectedCard = this.milestoneDataResult.filter((item: any) => {
      return item.milestone_id === list.milestone_id;
    });
    this.viewCertificateClick(selectedCard[0]);
  }

  /**
   * @description Get the milestones that are published and not published
   */
  getPublishedMilestones() {
    for (const value of this.milestoneDataResult) {
      if (value.previewMode) {
        this.milestonesPublished.push(value.milestone_id);
        this.milestonesPublishedInitial.push(value.milestone_id);
      } else {
        this.milestonesNotPublished.push(value.milestone_id);
      }
    }
  }

  /**
   * @description Remove the milestone from not published list on eye click and add it to publish list
   */
  publishMilestone(item) {
    if (item.previewMode) {
      item.previewMode = false;
      this.milestonesNotPublished.push(item.milestone_id);
      this.milestonesPublished.forEach((element, index) => {
        if (element === item.milestone_id) {
          this.milestonesPublished.splice(index, 1);
        }
      });
      if (this.milestonesPublished.length === 0) {
        this.disablePublish = true;
      } else {
        this.showToasterWarning();
        this.disablePublish = false;
      }
    } else if (!item.previewMode && this.milestonesPublished.length < 15) {
      this.disablePublish = false;
      item.previewMode = true;
      this.milestonesPublished.push(item.milestone_id);
      this.showToasterWarning();
      this.milestonesNotPublished.forEach((element, index) => {
        if (element === item.milestone_id) {
          this.milestonesNotPublished.splice(index, 1);
        }
      });
    } else {
      this.disablePublish = false;
      this.showBagfullPopup = true;
    }
    this.equalArray();
  }

  equalArray() {
    this.commonService.milestoneInfo.next(JSON.stringify(this.milestonesPublishedInitial) === JSON.stringify(this.milestonesPublished));
  }

  certificatePopupEvent(event): void {
    this.showCertificatePopup = event.showCertificatePopup;
  }

  managePopupEvent(event): void {
    this.showManagePopup = event;
  }

  bagfullPopupEvent(event): void {
    this.showBagfullPopup = event;
  }

  /**
   * @description Go to preview wall
   */
  goToPreviewWall() {
    this.showLoader = true;
    this.equalArray();
    const url = ApiUrlConstants.URL.updatePreviewFlags + '?userId=' + this.userData.email;
    const requestBody = {
      'on': this.milestonesPublished,
      'off': this.milestonesNotPublished,
    };
    this.apiService.ExecutePatch(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        this.goToPreviewPage.emit();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
    firebase.analytics().logEvent('navigation to preview wall');
  }

  showToasterWarning() {
    this.toastr.warning('Please go to preview & publish to update the changes for friends and family', 'Please publish changes');
  }

  /**
   * @description Convert the certificate background image into base64 to make it downloadable
   */
  convertImage(imageURL) {
    this.commonService.convertCertificateImage(imageURL)
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.selectedCardDetails.carddetails.main_background = reader.result;
        };
      });
  }

  viewCertificateClick(selectedCardDetails) {
    this.selectedCardDetails = selectedCardDetails;
    this.convertImage(this.selectedCardDetails.carddetails.main_background);
    this.popupData = {
      showCertificatePopup: true,
      certificateDetails: this.selectedCardDetails
    };
    this.showCertificatePopup = true;
  }

  goToHome() {
    this.goToHomePage.emit();
  }

}
