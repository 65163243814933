import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
    providedIn: 'root'
})
export class LoggingServiceService {

    constructor(
        private readonly analytics: AngularFireAnalytics
    ) {
    }

    public logEvent(eventName: string, eventParams: any = {}): void {
        this.analytics.logEvent(eventName, eventParams);
    }
}