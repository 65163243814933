import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as firebase from 'firebase/app';
import { ApiUrlConstants } from '../constants/api-urls';
import html2canvas from 'html2canvas';
import { ApiService } from '../services/common/api.service';
import { UrlConstants } from '../constants/image-urls';
import { CommonService } from '../services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import FileSaver from 'file-saver';


@Component({
  selector: 'app-view-certificate-popup',
  templateUrl: './view-certificate-popup.component.html',
  styleUrls: ['./view-certificate-popup.component.scss']
})
export class ViewCertificatePopupComponent implements OnInit {
  @Input() popupInput;
  @Output() popupOutput = new EventEmitter();
  @ViewChild('screen', { static: true }) screen: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;
  showAddReactionPopup = false;
  showLoader = true;
  popupData;
  certificationImagePath;
  logoPngImagePath: any = UrlConstants.URL.logoPngURL;
  profileImagePath = UrlConstants.URL.defaultProfileURL;
  logoImagePath = UrlConstants.URL.logoURL;
  closeImagePath = UrlConstants.URL.closeURL;
  loveImagePath = UrlConstants.URL.loveURL;
  baloonImagePath = UrlConstants.URL.baloonURL;
  clapImagePath = UrlConstants.URL.clapURL;
  facebookImagePath = UrlConstants.URL.facebookUrl;
  linkedinImagePath = UrlConstants.URL.linkedInURL;
  downloadImagePath = UrlConstants.URL.downloadURL;
  reactionType;
  certificateDetails;
  userData;
  uid;
  clapDetails: any = [];
  loveDetails: any = [];
  baloonDetails: any = [];
  love = true;
  baloon = false;
  clap = false;
  disableSocialShare = false;
  reactionCheck = 'loveR';
  displayName;
  socialShareHref;
  milestoneData: any;
  cardBg: any;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.displayName = sessionStorage.getItem('userName');
    this.certificateDetails = this.popupInput.certificateDetails;
    if (!this.popupInput.isRelative) {
      this.userData = JSON.parse(sessionStorage.getItem('user_data'));
      this.uid = this.userData.email;
    } else {
      this.uid = JSON.parse(sessionStorage.getItem('relative_id'));
    }
    this.convertLogoImage();
    this.getReactionsData();
  }

  convertLogoImage() {
    this.commonService.convertCertificateImage(UrlConstants.URL.logoPngURL)
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.logoPngImagePath = reader.result;
        };
      });
  }

  /**
   * @description Get reactions of selected milestone
   */
  getReactionsData() {
    const url = this.popupInput.isRelative ? (ApiUrlConstants.URL.getReactionDataFF
      + '?userId=' + this.popupInput.userEmailFF + '&user_milestone_ff_id=' + this.certificateDetails.milestone_id)
      : (ApiUrlConstants.URL.getReactionData
        + '?userId=' + this.uid + '&user_milestone_ff_id=' + this.certificateDetails.milestone_id);
    // const url = ApiUrlConstants.URL.getReactionData
    //  + '?userId=' + this.uid + '&user_milestone_ff_id=' + this.certificateDetails.milestone_id;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        const reactionsData = response.data;
        reactionsData.forEach((element) => {
          element.backgroundColor = this.getRandomColor();
          if (element.reactionType === 'claps') {
            this.clapDetails.push(element);
          }
          if (element.reactionType === 'love') {
            this.loveDetails.push(element);
          }
          if (element.reactionType === 'baloons') {
            this.baloonDetails.push(element);
          }
        });
        this.showLoader = false;
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }
  /**
   * @description Facebook and linkedIn share of selected certificate
   */
  socialShareURL() {
    html2canvas(this.screen.nativeElement).then(async canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      const certImg = canvas.toDataURL('image/png');

      const storage = firebase.storage();
      const storageRef = storage.ref();
      const imagesRef = storageRef.child('images');

      imagesRef.putString(certImg, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            this.socialShareHref = url;
          });
        }).catch((error) => {
          this.showLoader = false;
          this.toastr.error('Please try again later', 'Some error has occured');
        });
    });
  }

  loveReaction() {
    this.love = true;
    this.baloon = this.clap = false;
    this.reactionCheck = 'loveR';
  }
  baloonReaction() {
    this.baloon = true;
    this.love = this.clap = false;
    this.reactionCheck = 'baloonR';
  }
  clapReaction() {
    this.clap = true;
    this.love = this.baloon = false;
    this.reactionCheck = 'clapR';
  }

  /**
   * @description Close view certificate popup
   */
  public closeAction(): void {
    this.popupInput.showCertificatePopup = false;
    this.popupOutput.emit(this.popupInput);
  }

  /**
   * @description Reaction animations
   */
  public random(num) {
    return Math.floor(Math.random() * num);
  }

  public getRandomStyles() {
    let r = this.random(255);
    let g = this.random(255);
    let b = this.random(255);
    let mt = this.random(200);
    let ml = this.random(50);
    let dur = this.random(5) + 5;
    return `
    margin: ${mt}px 0 0 ${ml}px;
    animation: float ${dur}s ease-out infinite;
    animation-iteration-count:1;
    `;
  }
  public getRandomBalloonStyles() {
    let r = this.random(255);
    let g = this.random(255);
    let b = this.random(255);
    let mt = this.random(200);
    let ml = this.random(50);
    let dur = this.random(5) + 5;
    return `
     background-color: rgba(${r},${g},${b},1);
     color: rgba(${r},${g},${b},1);
     box-shadow: inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},1);
     margin: ${mt}px 0 0 ${ml}px;
     animation: float ${dur}s ease-out infinite;
    animation-iteration-count:1;
     `;
  }
  public createBalloons(num) {
    let balloonContainer = document.getElementById('balloon-container');
    let reactionWrapper = document.createElement('div');
    reactionWrapper.id = 'reaction-wrapper';
    for (var i = num; i > 0; i--) {
      let balloon = document.createElement('div');
      balloon.className = 'balloon-item';
      balloon.style.cssText = this.getRandomBalloonStyles();
      reactionWrapper.append(balloon);
    }
    balloonContainer.append(reactionWrapper);
    setTimeout(() => document.getElementById('reaction-wrapper').remove(), 3000);
  }

  public createReaction(num, reaction) {
    let balloonContainer = document.getElementById('balloon-container');
    let reactionWrapper = document.createElement('div');
    reactionWrapper.id = 'reaction-wrapper';
    for (var i = num; i > 0; i--) {
      let reactionItem = document.createElement('div');
      reactionItem.className = 'reaction-item';
      reactionItem.style.cssText = this.getRandomStyles();
      if (reaction === 'claps') {
        reactionItem.style.backgroundImage = 'url(assets/images/Clap.svg)';
      } else if (reaction === 'love') {
        reactionItem.style.backgroundImage = 'url(assets/images/Laugh.svg)';
      }
      reactionWrapper.append(reactionItem);
    }
    balloonContainer.append(reactionWrapper);
    setTimeout(() => document.getElementById('reaction-wrapper').remove(), 3000);
  }

  /**
   * @description Download user milestone certificate
   */
  downloadImage() {
    this.auditSharing("downloads")
    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) { // iOS = Iphone, Ipad, etc.
      this.createUserMilestoneCertificate('download-image');
    } else {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'certificate.png';
        this.downloadLink.nativeElement.click();
      });
    }
  }

  /**
   * @description Open enter name popup
   */
  addReactionPopup(reactionType) {
    this.reactionType = reactionType;
    this.popupData = {
      showAddReactionPopup: true,
      action: '',
      reactionType: this.reactionType,
      milestone_id: this.certificateDetails.milestone_id,
      uid: this.uid,
      userEmailFF: this.popupInput.userEmailFF
    };
    this.showAddReactionPopup = true;
  }

  /**
   * @description Close enter name popup
   */
  addReactionPopupEvent(event): void {
    this.showAddReactionPopup = event.showAddReactionPopup;
    if (event.action === 'addName') {
      this.certificateDetails.showReactedPeople = true;
      if (this.reactionType === 'baloons') {
        this.createBalloons(100);
        this.certificateDetails.baloons = true;
      } else {
        this.createReaction(100, this.reactionType);
        if (this.reactionType === 'claps') {
          this.certificateDetails.claps = true;
        } else {
          this.certificateDetails.love = true;
        }
      }
    }
    this.clapDetails.length = this.baloonDetails.length = this.loveDetails.length = 0;
    this.getReactionsData();
  }

  /**
   * @description Provide random background color for profile images
   */
  getRandomColor(): string {
    var x = Math.floor(Math.random() * 255);
    var y = Math.floor(Math.random() * 255);
    var z = Math.floor(Math.random() * 255);
    return "rgba(" + x + "," + y + "," + z + "," + 0.9 + ")";
  }

  /**
   * @description Create User Milestones Certificate
   */
  createUserMilestoneCertificate(action) {
    this.showLoader = true;
    this.milestoneData = JSON.parse(sessionStorage.getItem('milestoneData'));
    this.milestoneData.milestones.forEach((item) => {
      if (this.certificateDetails.milestone_id === item.milestone_id) {
        this.cardBg = item.carddetails.main_background;
      }
    });
    const url = this.popupInput.isRelative ? (ApiUrlConstants.URL.createUserMilestoneCertPathFF)
      : (ApiUrlConstants.URL.createUserMilestoneCertPath);
    // const url = ApiUrlConstants.URL.createUserMilestoneCertPath;
    const requestBody = {
      'studentName': this.certificateDetails.student_name,
      'cardDescription': this.certificateDetails.card_description,
      'userId': this.certificateDetails.student_email,
      'certificateBackground': this.certificateDetails.carddetails.main_background,
      'milestone_id': this.certificateDetails.milestone_id
    };
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        this.certificateDetails.cert_path = response.data;
        if (action === 'download-image') {
          this.downloadInIos();
        } else {
          this.socialShareImg(action);
        }
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description User Milestones Certificate download functionality in ios devices.
   */
  downloadInIos() {
    this.commonService.convertCertificateImage(this.certificateDetails.cert_path)
      .subscribe(response => {
        const imageBlob = response;
        FileSaver.saveAs(imageBlob, 'certificate.png');
      });
  }

  /**
   * @description Facebook and linkedIn share
   * @param action To identify whether facebook button or linkedIn button is clicked
   */
  socialShareAction(action) {
    this.disableSocialShare = true;
    if (this.certificateDetails.cert_path === null) {  // To check milestone certificate path is null
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        this.createUserMilestoneCertificate(action);
      } else {
        this.blobImg(action);
      }
    } else {
      this.socialShareImg(action);
    }
  }

  blobImg(action) {
    this.commonService.convertCertificateImage(this.certificateDetails.carddetails.main_background)
      .subscribe(response => {  // To convert certificate background image to blob
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.certificateDetails.cert_path = reader.result;
          if (!!this.certificateDetails.cert_path) {
            this.canvasImg(action);
          }
        };
      });
  }

  canvasImg(action) { // To convert certificate card html to canvas image
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      const certImg = canvas.toDataURL('image/jpeg');
      this.firestoreUploadImg(action, certImg);
    });
  }

  firestoreUploadImg(action, certImg) {
    const dateNow = Date.now();
    const storage = firebase.storage(); // To upload canvas certificate image to firestore
    const storageRef = storage.ref();
    const imagesRef = storageRef.child(`userMilestoneCertificates/${this.popupInput.certificateDetails.student_email}/${dateNow}.jpeg`);
    imagesRef.putString(certImg, 'data_url')
      .then((snapshot) => { // To get download url of uploaded certificate image from firestore
        snapshot.ref.getDownloadURL().then((url) => {
          this.certificateDetails.cert_path = url;
          this.updateCertImg(url);
          this.socialShareImg(action);
        });
      }).catch((error) => {
        this.showLoader = false;
        this.toastr.error('Please try again later', 'Some error has occured');
      });
  }

  updateCertImg(url) {    // API integration to upload certificate image to milestone collection in firestore when certificate path is null
    const apiUrl = this.popupInput.isRelative ?
      (ApiUrlConstants.URL.updateUserMilestoneCertPathFF + '?userId=' + this.popupInput.certificateDetails.student_email)
      : (ApiUrlConstants.URL.updateUserMilestoneCertPath + '?userId=' + this.popupInput.certificateDetails.student_email);
    // const apiUrl = ApiUrlConstants.URL.updateUserMilestoneCertPath + '?userId=' + this.popupInput.certificateDetails.student_email;
    const requestBody = {
      'milestone_id': this.certificateDetails.milestone_id,
      'cert_path': url
    };
    this.apiService.ExecutePatch(apiUrl, requestBody, (response) => {
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  socialShareImg(action) {
    this.disableSocialShare = false;
    const shareImg = this.certificateDetails.cert_path;
    const altAttr = '';
    if (action === 'facebook-share') {  // To share certificate image in facebook
      window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareImg) + '&t='
        + encodeURIComponent(altAttr) + '&redirect_uri=http://www.facebook.com/', 'sharer');
        this.auditSharing("facebook")
    } else if (action === 'linkedIn-share') { // To share certificate image in linkedIn
      window.open('http://www.linkedin.com/shareArticle?mini=true&url='
        + encodeURIComponent(shareImg) + '&title=' + encodeURIComponent(altAttr), 'sharer');
        this.auditSharing('linkedin')
    }
  }

  auditSharing(type){
    const url = this.popupInput.isRelative === true ? ApiUrlConstants.URL.socialShareAuditFF : ApiUrlConstants.URL.socialShareAudit;
    const requestBody = {
      'userId': this.popupInput.certificateDetails.student_email,
      'type': type,
      'milestone_id': this.certificateDetails.milestone_id
    };

    this.apiService.ExecutePost(url, requestBody, (response) => {
      console.log(response)
      if (response.status === 'success') {
      }
    }, (error) => {
      console.error(error)
    });
  }
}
