import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './loader/loader.component';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';                    // For setup
import { AngularFirestoreModule } from '@angular/fire/firestore';     // For database --> firestore
import { AngularFireStorageModule } from '@angular/fire/storage';     // For Storage
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';   // For database
import { AngularFirestore } from '@angular/fire/firestore';          // For database --> firestore
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';


import { MomentsWallComponent } from './components/moments-wall/moments-wall.component';
import { PreviewWallComponent } from './components/preview-wall/preview-wall.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { BagFullPopupComponent } from './shared/bag-full-popup/bag-full-popup.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ManagePopupComponent } from './shared/manage-popup/manage-popup.component';
import { PublishPopupComponent } from './shared/publish-popup/publish-popup.component';
import { ViewCertificatePopupComponent } from './shared/view-certificate-popup/view-certificate-popup.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AuthGuardService } from './shared/services/common/auth-guard.service';
import { AuthenticationService } from './shared/services/common/authentication.service';
import { ApiService } from './shared/services/common/api.service';
import { DataService } from './shared/services/common/data.service';
import { AddReactionPopupComponent } from './shared/add-reaction-popup/add-reaction-popup.component';
import { AddFriendsandfamilyPopupComponent } from './shared/add-friendsandfamily-popup/add-friendsandfamily-popup.component';
import { IntercomModule } from 'ng-intercom';
import { NoMilestonesPopupComponent } from './shared/no-milestones-popup/no-milestones-popup.component';
import { UnsubscribeEmailComponent } from './shared/unsubscribe-email/unsubscribe-email.component';
@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    LoaderComponent,
    AppComponent,
    SplashScreenComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    ManagePopupComponent,
    MomentsWallComponent,
    ViewCertificatePopupComponent,
    PreviewWallComponent,
    PublishPopupComponent,
    BagFullPopupComponent,
    AddReactionPopupComponent,
    AddFriendsandfamilyPopupComponent,
    NoMilestonesPopupComponent,
    UnsubscribeEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFireAnalyticsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features,
    AngularFireDatabaseModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ timeOut: 3000, preventDuplicates: true, positionClass: 'toast-bottom-center', closeButton: true }),
    IntercomModule.forRoot({
      appId: 'ifx0lzku', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuardService, AuthenticationService, DataService, ToastrService, ApiService,
    AngularFirestore, ScreenTrackingService, UserTrackingService
    // { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ],
})
export class AppModule { }
