<div class="popup-overlay">
    <div class="manage-wrapper fade-in">
        <span class="close-img-wrapper" (click)="closeAction()" tabindex="1" id="popup-close">
            <img [src]="closeImagePath" alt="close-img" />
        </span>
        <form [formGroup]="notificationForm">
            <div class="main-img-wrapper"><img [src]="manageImagePath" alt="main-img" /></div>
            <p class="heading">Manage Friends & Family</p>
            <p class="desc">Hey! You can manage your friends and family from here.
            </p>
            <div class="notify-wrapper">
                <p>Get notified when Friends and family react to the certificate</p>
                <label class="toggle-switch">
                    <input type="checkbox" formControlName="notification" (change)="updateFriendsFamily($event)"
                        aria-label="notifiationLabel">
                    <div class="slider"></div>
                </label>
            </div>

            <p class="add-email">Add email address </p>
            <div class="form-element">
                <div class="input-wrapper">
                    <input required formControlName="nickName" placeholder="Nick Name" type="text"
                        (keydown.enter)="false" aria-label="nameLabel">
                    <p class="error"
                        *ngIf="!notificationForm.controls.nickName.pristine && notificationForm.controls.nickName.errors && notificationForm.controls.nickName.errors.pattern">
                        Name is required!
                    </p>
                </div>
                <div class="input-wrapper">
                    <input required formControlName="email" placeholder="Email" type="text" (input)="checkEmailExist()"
                        (keydown.enter)="false" aria-label="emailLabel">
                    <p class="error"
                        *ngIf="!notificationForm.controls.email.pristine && notificationForm.controls.email.errors && notificationForm.controls.email.errors.required">
                        Email is required!
                    </p>
                    <p class="error"
                        *ngIf="notificationForm.controls.email.errors && notificationForm.controls.email.errors.pattern">
                        Please provide a valid email address!
                    </p>
                    <p class="error" *ngIf="emailExist">
                        Email already exist!
                    </p>
                </div>
                <button class="add-img-wrapper" type="submit" (click)="addFriendsFamily()" id="addFF" tabindex="1"
                    [disabled]="notificationForm.invalid || emailExist">
                    <img [src]="addImagePath" alt="add-email-img" />
                </button>
            </div>
            <div *ngIf="relativesList.length>0" class="email-collection">
                <div class="emails-wrapper" *ngFor="let item of relativesList let i = index">
                    <div class="name-item"> {{item?.name}} </div>
                    <div class="email-item">{{item?.email}}</div>
                    <span class="delete-img-wrapper" (click)="deleteFriendsFamily(item)" tabindex="{{i}}">
                        <img [src]="closeImagePath" alt="close-img" />
                    </span>
                </div>
            </div>
        </form>
        <app-loader *ngIf="showLoader"></app-loader>
    </div>
</div>