<div class="popup-overlay">
    <div class="preview-wrapper view-certificate fade-in" [ngStyle]="{'width': (popupInput.isRelative && !certificateDetails.showReactedPeople) ? '60%' : '',
    'flex-direction': (popupInput.isRelative) ? 'initial' : ''}">
        <span class="close-img-wrapper" id="popup-close" (click)="closeAction()" tabindex="1">
            <img [src]="closeImagePath" alt="close-img" />
        </span>
        <div class="left-section" id="balloon-container"
            [ngStyle]="{'width': (popupInput.isRelative && !certificateDetails.showReactedPeople) ? '100%' : ''}">
            <div #screen class="certificate-wrapper"
                [style.backgroundImage]="'url('+ certificateDetails.carddetails.main_background +')'">
                <div class="logo-wrapper"> <img [src]="logoPngImagePath" alt="logo_imge" /></div>
                <div class="text-wrapper">
                    <h1 class="heading">Congrats <strong>{{displayName}}</strong> </h1>
                    <div class="card-desc" [innerHTML]="certificateDetails?.card_description"></div>
                </div>
                <div class="slanting-box-wrapper view-cert">
                    <div class="tile"><span>Continue your journey</span></div>
                    <div class="tile"><span>Aim higher</span></div>
                    <div class="tile"><span>Increase your potential</span></div>
                </div>
                <div class="cert-footer">EDUCATION FOR GOOD</div>
            </div>
            <div id="download">
                <img #canvas alt="canvas-img">
                <a #downloadLink></a>
            </div>
            <div class="popup-footer">
                <p *ngIf="popupInput.isRelative && !certificateDetails.showReactedPeople">Show your love and support by
                    clicking any one of the below.</p>
                <p *ngIf="popupInput.isRelative && certificateDetails.showReactedPeople">Thank you for your celebration.
                </p>
                <div class="icon-wrapper popup-icons"
                    [ngStyle]="{'justify-content': !popupInput.isRelative ? 'center' : '' }"
                    [ngClass]="popupInput.isRelative ? 'mobRelative' : ''">
                    <ul class="reaction-icons" *ngIf="popupInput.isRelative">
                        <li class="img-wrap" (click)="addReactionPopup('love')"
                            [ngClass]="certificateDetails.love ? 'highlight-reaction' : ''">
                            <img [src]="loveImagePath" alt="love_img" />
                        </li>
                        <li class="img-wrap" (click)="addReactionPopup('baloons')"
                            [ngClass]="certificateDetails.baloons ? 'highlight-reaction' : ''">
                            <img [src]="baloonImagePath" alt="baloon_img" />
                        </li>
                        <li class="img-wrap" (click)="addReactionPopup('claps')"
                            [ngClass]="certificateDetails.claps ? 'highlight-reaction' : ''">
                            <img [src]="clapImagePath" alt="clap_img" />
                        </li>
                    </ul>
                    <div class="share-icons web-share">
                        <div class="share-btns">
                            <button class="social-share" (click)="socialShareAction('facebook-share')"
                                [ngClass]="disableSocialShare ? 'disable-share' : ''">
                                <img [src]="facebookImagePath" alt="fb_img" /></button>
                            <button class="social-share" (click)="socialShareAction('linkedIn-share')"
                                [ngClass]="disableSocialShare ? 'disable-share' : ''">
                                <img [src]="linkedinImagePath" alt="li_img" /></button>
                            <div class="loader-wrapper" *ngIf="disableSocialShare">
                                <div class="loader"></div>
                            </div>
                        </div>
                        <div class="img-wrap" id="download-item" (click)="downloadImage()" tabindex="1">
                            <img [src]="downloadImagePath" alt="download_img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-section" [ngClass]="popupInput.isRelative ? 'mobStudent' : ''"
            [ngStyle]="{'display': (popupInput.isRelative && !certificateDetails.showReactedPeople) ? 'none' : ''}">
            <p class="heading">Reactions</p>

            <ul class="reaction-types">
                <li (click)="loveReaction()" [ngClass]="reactionCheck == 'loveR' ? 'active' : ''" tabindex="1">
                    <div class="reaction-img-wrapper">
                        <img [src]="loveImagePath" alt="love-img" />
                    </div><span class="reaction-count">{{loveDetails?.length}}</span>
                </li>
                <li (click)="baloonReaction()" [ngClass]="reactionCheck == 'baloonR' ? 'active' : ''" tabindex="1">
                    <div class="reaction-img-wrapper">
                        <img [src]="baloonImagePath" alt="baloon-img" />
                    </div><span class="reaction-count">{{baloonDetails?.length}}</span>
                </li>
                <li (click)="clapReaction()" [ngClass]="reactionCheck == 'clapR' ? 'active' : ''" tabindex="1">
                    <div class="reaction-img-wrapper">
                        <img [src]="clapImagePath" alt="clap-img" />
                    </div><span class="reaction-count">{{clapDetails?.length}}</span>
                </li>
            </ul>

            <ul class="people-reacted" *ngIf="love">
                <li *ngFor="let list of loveDetails; let i=index">
                    <div class="profile-img-wrapper" [ngStyle]="{'backgroundColor': list.backgroundColor}">
                        <p>{{list.name.charAt(0)}}</p>
                    </div><span>{{list.name}}</span>
                </li>
            </ul>
            <ul class="people-reacted" *ngIf="baloon">
                <li *ngFor="let list of baloonDetails; let i=index">
                    <div class="profile-img-wrapper" [ngStyle]="{'backgroundColor': list.backgroundColor}">
                        <p>{{list.name.charAt(0)}}</p>
                    </div><span>{{list.name}}</span>
                </li>
            </ul>
            <ul class="people-reacted" *ngIf="clap">
                <li *ngFor="let list of clapDetails; let i=index">
                    <div class="profile-img-wrapper" [ngStyle]="{'backgroundColor': list.backgroundColor}">
                        <p>{{list.name.charAt(0)}}</p>
                    </div><span>{{list.name}}</span>
                </li>
            </ul>
        </div>
        <div class="icon-wrapper mob-share">
            <div class="share-icons">
                <div class="share-btns">
                    <button class="social-share" (click)="socialShareAction('facebook-share')"
                        [ngClass]="disableSocialShare ? 'disable-share' : ''">
                        <img [src]="facebookImagePath" alt="fb_img" /></button>
                    <button class="social-share" (click)="socialShareAction('linkedIn-share')"
                        [ngClass]="disableSocialShare ? 'disable-share' : ''">
                        <img [src]="linkedinImagePath" alt="li_img" /></button>
                    <div class="loader-wrapper" *ngIf="disableSocialShare">
                        <div class="loader"></div>
                    </div>
                </div>
                <div class="img-wrap" id="download-item" (click)="downloadImage()" tabindex="1">
                    <img [src]="downloadImagePath" alt="download_img" />
                </div>
            </div>
        </div>
        <app-loader *ngIf="showLoader"></app-loader>
    </div>
</div>
<app-add-reaction-popup *ngIf="showAddReactionPopup" [popupInput]="popupData"
    (popupOutput)="addReactionPopupEvent($event)"></app-add-reaction-popup>