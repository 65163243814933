<div class="famewall-wrapper">
    <app-header [currentPage]="'momentsPage'" (goToHomePage)="goToHome()"></app-header>
    <div class="left-section">
        <div class="logo-wrapper">
            <button id="home-btn" (click)="goToHome()">
                <img [src]="logoImagePath" alt="logo_img" />
            </button>
        </div>
        <div class="testimonial">
            <div class="quotes-wrapper">
                <img [src]="quotesImagePath" alt="quotes_img" />
            </div>
            <p class="quote">The more you <strong>celebrate
                    your life,</strong> The more there
                <strong> is in life to celebrate</strong>
            </p>
            <p class="author">Oprah Winfrey</p>
        </div>
        <div class="bottom-left-wrapper">
            <img [src]="momentsImagePath" alt="bg_img" />
        </div>
    </div>
    <div class="right-section">
        <div class="logo-wrapper mobile-logo" (click)="goToHome()">
            <img [src]="logoImagePath" alt="logo_img" />
        </div>
        <div class="right-header">
            <div class="moments">
                <h1 class="main-head">Share Moments</h1>
            </div>
            <div class="momentsBtn-wrapper">
                <button (click)="showManagePopup=true" class="moments-btn manage-web" id="manageFnF"
                    type="submit">Manage Friends and Family</button>
                <button (click)="goToPreviewWall()" class="moments-btn moments-btn2" type="submit"
                    [ngClass]="disablePublish ? 'disable-publish' : ''">Preview & Publish</button>
            </div>
        </div>
        <p class="description">Please select from below to customize your friends and family view (moments wall)</p>
        <ul class="certificates-wrapper" id="certificate-card">
            <li class="certificate" *ngFor="let list of milestoneDataResult; let i=index">
                <div class="certificate-item fade-in"
                    [ngClass]="{'visible-certificate':list.previewMode, 'hidden-certificate' : !list.previewMode}">
                    <div class="popover_wrapper">
                        <div class="eye-wrapper" (click)="publishMilestone(list)" tabindex="{{i}}">
                            <img *ngIf="list.previewMode" [src]="blackEyeImagePath" alt="hide-card-img" />
                            <img *ngIf="!list.previewMode" [src]="whiteEyeImagePath" alt="show-card-img" />
                        </div>
                        <div *ngIf="!list.previewMode" class="popover_content">Add to My Moments</div>
                        <div *ngIf="list.previewMode" class="popover_content">Remove from My Moments</div>
                    </div>
                    <div class="certificate-wrapper"
                        [style.backgroundImage]="'url('+ list.carddetails.main_background +')'">
                        <div class="card-item" (click)="getSelectedCard(list)" tabindex="{{i}}">
                            <div class="logo-wrapper"> <img [src]="logoImagePath" alt="logo_img" /></div>
                            <div class="text-wrapper">
                                <p class="heading">Congrats <strong>{{userData.displayName}}</strong> </p>
                                <div class="card-desc" [title]="list?.card_title" [innerHTML]="list?.card_description">
                                </div>
                            </div>
                            <div class="cert-footer">EDUCATION FOR GOOD</div>
                        </div>
                    </div>
                    <p class="term">{{list.milestone_title}}</p>
                </div>
            </li>
        </ul>
    </div>
    <app-loader *ngIf="showLoader"></app-loader>
</div>
<app-view-certificate-popup *ngIf="showCertificatePopup" [popupInput]="popupData"
    (popupOutput)="certificatePopupEvent($event)">
</app-view-certificate-popup>

<app-manage-popup *ngIf="showManagePopup" [popupInput]="showManagePopup" (popupOutput)="managePopupEvent($event)">
</app-manage-popup>

<app-bag-full-popup *ngIf="showBagfullPopup" [popupInput]="showBagfullPopup" (popupOutput)="bagfullPopupEvent($event)">
</app-bag-full-popup>