import { environment } from '../../../environments/environment';

export class UrlConstants {
    public static readonly baseUrl = environment.imageBaseURL;
    public static readonly URL = {
        facebookUrl: `${UrlConstants.baseUrl}Facebook.svg?alt=media&token=88fe2baf-6fa7-42ca-9a55-546f863400ed`,
        linkedInURL: `${UrlConstants.baseUrl}Linkedin.svg?alt=media&token=951a13a2-45a8-40ee-aa1f-f1b05abd47bb`,
        downloadURL: `${UrlConstants.baseUrl}Download%20Icon.svg?alt=media&token=0ea3bbf2-5620-4c6a-8a91-e113a6ae6a94`,
        logoURL: `${UrlConstants.baseUrl}Logo.svg?alt=media&token=68cb2f51-d8ec-4200-9a00-a48bded90089`,
        logoPngURL: `${UrlConstants.baseUrl}Logo.png?alt=media&token=9d961dd4-6176-460a-96fa-b325d8519c03`,
        logoWhiteURL: `${UrlConstants.baseUrl}Logo%20white.svg?alt=media&token=06601dca-7286-4df7-b861-07f05a6b2106`,
        leftArrowURL: `${UrlConstants.baseUrl}Left.svg?alt=media&token=1700ec10-2d81-4bf2-9297-68e765da164e`,
        rightArrowURL: `${UrlConstants.baseUrl}Right.svg?alt=media&token=eaa1fb93-45a4-4885-8972-2673978d9e6a`,
        backArrowURL: `${UrlConstants.baseUrl}Arrow%20left.svg?alt=media&token=8d70e500-9aeb-4458-8b01-223bc144ba9e`,
        warningURL: `${UrlConstants.baseUrl}Warning%20icon.svg?alt=media&token=a15de34b-c721-4a44-a917-8e0da6fa7549`,
        partyURL: `${UrlConstants.baseUrl}Party.svg?alt=media&token=62a57358-0afd-4e21-8b63-04653eba5d5b`,
        addURL: `${UrlConstants.baseUrl}Add%20icon.svg?alt=media&token=52060f66-8627-430e-97c0-4263d60c2c12`,
        closeURL: `${UrlConstants.baseUrl}CloseIcon.svg?alt=media&token=cf2c9511-e872-44ba-8251-b1889e7b8527`,
        closeIconURL: `${UrlConstants.baseUrl}Close.svg?alt=media&token=c70a866e-dc69-4294-8ad5-17c95b2f5870`,
        ffWarningURL: `${UrlConstants.baseUrl}FFwarning.svg?alt=media&token=e85de61c-0550-4d42-aca4-b664fa77019d`,
        friendsFamilyURL: `${UrlConstants.baseUrl}FriendsFamily.svg?alt=media&token=4b7cd6da-3f06-4e6f-a469-52c73b5f93f0`,
        clapURL: `${UrlConstants.baseUrl}Clap.svg?alt=media&token=11d18350-4c7c-43f0-8515-be99a59018a3`,
        baloonURL: `${UrlConstants.baseUrl}Baloon.svg?alt=media&token=bcb4b43f-0044-465e-ad0b-7b3fe1514f02`,
        loveURL: `${UrlConstants.baseUrl}Laugh.svg?alt=media&token=8dc44f60-95de-4c33-8009-73aa73910fe9`,
        defaultProfileURL: `${UrlConstants.baseUrl}DefaultProfile.svg?alt=media&token=cf5a184f-3c1d-4eb6-a95b-cec891f015d7`,
        hamburgurURL: `${UrlConstants.baseUrl}Hamburgur.svg?alt=media&token=ed303f5f-89ee-44ee-a517-6439d8e3f7b8`,
        mobileDotsURL: `${UrlConstants.baseUrl}MobileDots.svg?alt=media&token=6e034706-2cf1-4420-a0cc-e6c84d53b2fe`,
        publishURL: `${UrlConstants.baseUrl}Publish.svg?alt=media&token=2ceb442f-66f5-4130-a758-66f28514d3d4`,
        addReactionURL: `${UrlConstants.baseUrl}AddReaction.svg?alt=media&token=e9563bd6-bb31-4116-a8ae-201fc6c8759f`,
        bagFullURL: `${UrlConstants.baseUrl}BagFull.svg?alt=media&token=1f385a98-89d4-499a-a990-356ad19c6752`,
        notifyEyeURL: `${UrlConstants.baseUrl}NotifyEye.svg?alt=media&token=d6b9e5d3-7b6a-4d91-afcb-27bb10b04a78`,
        blackEyeURL: `${UrlConstants.baseUrl}Black%20eye.svg?alt=media&token=9d9dfcf9-94b1-466f-8c86-2c8f78ccc458`,
        whiteEyeURL: `${UrlConstants.baseUrl}WhiteEye.svg?alt=media&token=597646b3-dad9-448c-bec9-7edf3c837f01`,
        quoteSignURL: `${UrlConstants.baseUrl}QuotesSign.svg?alt=media&token=7d0175d4-6cea-4e80-8709-eda9f5f67fcc`,
        momentsWallURL: `${UrlConstants.baseUrl}MomentsWall.svg?alt=media&token=e2ae6c89-ce42-4e7a-bcba-3fdb4731ca21`,
        backgroundURL: `${UrlConstants.baseUrl}Background.png?alt=media&token=e1bb25e3-e231-41be-a22c-61f06eb34460`,
        tilewoodBgURL: `${UrlConstants.baseUrl}TilewoodBg.png?alt=media&token=c2396652-e4fb-42e9-8a88-0c4e0f314784`,
        hotelwallBgURL: `${UrlConstants.baseUrl}HotelwallBg.png?alt=media&token=eb2cde51-b8b1-4944-b7ce-30a4ce56c57c`,
        previewwallBgURL: `${UrlConstants.baseUrl}Previewbg.png?alt=media&token=2a522b9b-e60a-4220-84d2-8716ff8742e8`,
        brickwallBgURL: `${UrlConstants.baseUrl}BrickwallBg.png?alt=media&token=3d34e369-6ab8-488f-98fd-ff16eec76a7f`,
        manageRelativesURL: `${UrlConstants.baseUrl}Add%20relatives.svg?alt=media&token=61ab8caf-56c0-4eb2-b78f-c69351c35184`,
        addFandFURL: `${UrlConstants.baseUrl}Add%20FandF.svg?alt=media&token=1a662a29-4850-4dee-9498-488181096ac4`,
        noMilestonesURL: `${UrlConstants.baseUrl}No%20milestones.png?alt=media&token=5ff36882-72a8-4107-a289-ab261f1f7701`,
        unsubscribeURL: `${UrlConstants.baseUrl}Unsubscribe.svg?alt=media&token=4d12d373-8c9c-4826-bab1-5150bf3b89e4`,
        cancelUnsubscribeURL: `${UrlConstants.baseUrl}Cancel%20Unsubscribe.svg?alt=media&token=6181dae3-dfd7-47d9-8211-e734ea12c36c`
    };
}
