import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UrlConstants } from '../constants/image-urls';
import { AuthenticationService } from '../services/common/authentication.service';
import { CommonService } from '../services/common/common.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() accessTokenReceived: any;
  @Input() currentPage: any;
  @Output() goToMomentsPage = new EventEmitter();
  @Output() goToHomePage = new EventEmitter();
  userName;
  showManagePopup = false;
  manageImagePath = UrlConstants.URL.manageRelativesURL;
  logoImagePath = UrlConstants.URL.logoURL;
  closeImagePath = UrlConstants.URL.closeIconURL;
  hamburgurImgPath = UrlConstants.URL.hamburgurURL;
  profileImage = UrlConstants.URL.defaultProfileURL;
  userData: any;

  constructor(
    private commonService: CommonService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.userName = this.commonService.getUserName();
    this.profileImage = JSON.parse(localStorage.getItem('user_image'));
  }

  /**
   * @description Logout current user
   */
  microsoftLogout() {
    this.auth.logout();
  }

  /**
   * @description Routing to moments wall page
   */
  goToMomentsWall() {
    this.goToMomentsPage.emit();
    firebase.analytics().logEvent('navigation to share moments');
  }

  goToHome() {
    this.goToHomePage.emit();
  }

  /**
   * @description To show side navbar for header on clicking hamburgur menu in mobile devices
   */
  openNav() {
    document.getElementById('sidenav-wrapper').style.width = '100%';
  }

  /**
   * @description To hide side navbar for header on clicking close button in mobile devices
   */
  closeNav() {
    document.getElementById('sidenav-wrapper').style.width = '0';
  }

  /**
   * @description Close add F & F popup
   */
  managePopupEvent(event): void {
    this.showManagePopup = event;
  }
}
