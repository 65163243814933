import { Component, Input, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/shared/constants/image-urls';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  showSplash = true;

  logoImagePath = UrlConstants.URL.logoURL;
  constructor() { }

  ngOnInit(): void {
    this.showOrHideSplash(window.innerWidth);
  }
  showOrHideSplash(screenSize) {
    /**
     * @description Show splash screen for few seconds after login
     */
    if (screenSize > 768) {
      document.body.style.overflowY = 'hidden';
    }
    setTimeout(() => {
      this.showSplash = !this.showSplash;
      if (screenSize > 768) {
        document.body.style.overflowY = 'scroll';
      }
    }, 3000);
  }
}
