import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as firebase from 'firebase/app';
import { ApiUrlConstants } from './shared/constants/api-urls';
import { UrlConstants } from './shared/constants/image-urls';
import { ApiService } from './shared/services/common/api.service';
import { CommonService } from './shared/services/common/common.service';
import { DataService } from './shared/services/common/data.service';
import { AuthenticationService } from './shared/services/common/authentication.service';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { LoggingServiceService } from './shared/services/logging-service.service';
import { ToastrService } from 'ngx-toastr';
import FileSaver from 'file-saver';

@Component({
  selector: 'walden-moments-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('screen', { static: false }) screen: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;

  userData;
  idToken;
  showCertificatePopup = false;
  currentUser = {};
  selectedIndex: number;
  screenWidth;
  sliceStart: number;
  sliceEnd: number;
  showSplash = false;
  accessTokenReceived = false;
  noMilestonesPopup = false;
  homeScreenDisplay: any;
  certificationImagePath;
  logoImagePath = UrlConstants.URL.logoURL;
  logoPngImagePath: any = UrlConstants.URL.logoPngURL;
  facebookImagePath = UrlConstants.URL.facebookUrl;
  linkedinImagePath = UrlConstants.URL.linkedInURL;
  downloadImagePath = UrlConstants.URL.downloadURL;
  congratsImagePath = UrlConstants.URL.partyURL;
  leftImagePath = UrlConstants.URL.leftArrowURL;
  rightImagePath = UrlConstants.URL.rightArrowURL;
  milestoneDataCopy: any[] = [];
  milestoneDataResult: any;
  popupData: any;
  viewPage = 'homePage';
  showAddPopup = false;
  relativesList: any;
  isRelative = false;
  showLoader = false;
  largeScreen = false;
  disableSocialShare = false;
  socialShareHref;
  ffUid: any;
  reactionsCount: any;
  cardBg;
  milestoneData: any;
  unsubscribeToken = false;
  unsubscribeEmails;
  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private commonService: CommonService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    public intercom: Intercom,
    private loggingService: LoggingServiceService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.showLoader = true;

    history.pushState(null, null, location.href);   // Disable browser back navigation
    window.onpopstate = () => history.go(1);

    this.route.queryParams.subscribe(res => {
      this.ffUid = res.uid;
      this.unsubscribeToken = res.unsubscribe;
      if (this.unsubscribeToken) {
        this.createCustomTokenFF('unsubscribe');
      } else if (this.ffUid) {   // For F&F user
        this.isRelative = true;
        sessionStorage.setItem('isRelative', JSON.stringify(this.isRelative));
        sessionStorage.setItem('relative_id', JSON.stringify(this.ffUid));
        this.createCustomTokenFF('FandF');
      } else {  // For student user
        firebase.auth().onAuthStateChanged((user: any) => {
          if (!this.ffUid && !this.unsubscribeToken) {
            if (user) {
              this.userData = user;
              this.loggingService.logEvent('walden-moments-login');
              this.intercom.boot({
                app_id: 'ifx0lzku',
                name: this.userData.displayName, // Full name
                email: this.userData.email, // Email address
                created_at: this.userData.createdAt, // Signup date as a Unix timestamp,
                // Supports all optional configuration.
                widget: {
                  'activator': '#intercom'
                }
              });
              sessionStorage.setItem('userName', this.userData.displayName);
              sessionStorage.setItem('user_data', JSON.stringify(this.userData));
              firebase.auth().getRedirectResult().then((result: any) => {
                if (result && result.credential) {
                  const accessToken = result.credential.accessToken;
                  sessionStorage.setItem('access_token', accessToken);
                  this.getProfilePic();
                  sessionStorage.setItem('token', result.credential['idToken']);
                } else {
                  this.accessTokenReceived = false;
                }
                if (sessionStorage.getItem('token') === null) {
                  const provider = new firebase.auth.OAuthProvider('microsoft.com');
                  firebase.auth().currentUser.reauthenticateWithRedirect(provider);
                }
                firebase.auth().currentUser.getIdToken(true).then((response) => {
                  sessionStorage.setItem('token', response);
                  if (sessionStorage.getItem('token')) {
                    this.getData();
                    this.createUser();
                    this.welcomeScreen();
                    this.getUserMilestoneData();
                    this.convertLogoImage();
                  }
                });
              })
                .catch((error) => {
                  if (error.code === 'auth/invalid-credential') {
                    window.location.reload();
                  }
                });
            } else {
              this.accessTokenReceived = true;
              this.authService.login(
                (data) => {
                }
              );
            }
          }
        });
      }
    });
  }

  getData() {
    this.dataService.getData()
      .subscribe(result => {
        this.currentUser = result;
      }, error => {
      });
  }

  /**
   * @description Condition to show and hide splash screen
   */
  welcomeScreen() {
    if ((sessionStorage.getItem('spash_display') === null) ||
      (sessionStorage.getItem('spash_display') === '')) {
      this.showSplash = true;
      this.showLoader = false;
      sessionStorage.setItem('spash_display', JSON.stringify(this.showSplash));
    } else {
      this.showSplash = false;
    }
  }

  /**
   * @description Create Custom Tokens for F&F
   */
  createCustomTokenFF(action) {
    const requestBody = '';
    this.showLoader = true;
    const url = ApiUrlConstants.URL.createCustomTokenFF;
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.status === 'success') {
        this.showLoader = false;
        const customToken = response?.token;
        // const ffIDtoken = this.getFFidToken(customToken);
        sessionStorage.setItem('FF_idToken', customToken);
        if (action === 'unsubscribe') {
          this.unsubscribeEmails = true;
          sessionStorage.setItem('unsubscribe', JSON.stringify(this.unsubscribeEmails));
        } else if (action === 'FandF') {
          this.goToPreviewPage();
        }
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }
  // async getFFidToken(customToken){
  //   const userConfig = await firebase.auth().signInWithCustomToken(customToken);
  //   const idToken = (await userConfig).user.getIdToken();
  //   return idToken;
  // }

  /**
   * @description Fetch profile image of logged in user
   */
  getProfilePic() {
    this.commonService.getProfileImage()
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          let imageURL = reader.result;
          if (null === reader.result || '' === reader.result) {
            imageURL = 'assets/images/default-profile-user.svg';
          } else {
            imageURL = reader.result;
          }
          localStorage.setItem('user_image', JSON.stringify(imageURL));
          this.accessTokenReceived = true;
        };
      });
  }

  /**
   * @description Convert the certificate background image into base64 to make it downloadable
   */
  convertImage(imageURL) {
    this.commonService.convertCertificateImage(imageURL)
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.certificationImagePath = reader.result;
          this.homeScreenDisplay.carddetails.main_background = reader.result;
        };
      });
  }

  /**
   * @description Create user
   */
  createUser() {
    const url = ApiUrlConstants.URL.createUserURL;
    const requestBody = {
      'userId': this.userData.email,
      'firstName': this.userData.displayName.replace(/ .*/, ''),
      'lastName': this.userData.displayName.slice(this.userData.displayName.lastIndexOf(' ') + 1),
      'lastLogin': new Date(this.userData.metadata.lastSignInTime).toISOString(),
      'emailId': this.userData.email,
      'friends_family': [],
      'show_notification': false
    };
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.Status === 'Success') {
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Convert certificate logo image to base64 format
   */
  convertLogoImage() {
    this.commonService.convertCertificateImage(UrlConstants.URL.logoPngURL)
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.logoPngImagePath = reader.result;
        };
      });
  }

  /**
   * @description Fetch milestone data of the logged in user
   */
  getUserMilestoneData() {
    const url = ApiUrlConstants.URL.getUserMilestoneData + '?userId=' + this.userData.email;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.milestoneData = response?.data;
        sessionStorage.setItem('milestoneData', JSON.stringify(response?.data));
        this.milestoneDataResult = response?.data?.milestones;
        sessionStorage.setItem('ff_uid', response?.data?.docId);
        sessionStorage.setItem('skin-color', response.data.skin_class);
        this.sortByAchievementDate();
      }
    }, (error) => {
      this.showLoader = false;
      this.noMilestonesPopup = true;
    });
  }

  /**
   * @description Sort milestones in the order of latest achievements
   */
  sortByAchievementDate(): void {
    this.milestoneDataResult.sort((b, a) =>
      new Date(b.created_date).getTime() - new Date(a.created_date).getTime());
    this.milestoneDataResult.forEach(list => {
      list.mouseHover = false;
    });
    this.milestoneDataCopy = this.milestoneDataResult;
    this.selectedIndex = this.milestoneDataResult.length - 1;
    this.getScreenSize(window.innerWidth);
    this.getMilestone(this.selectedIndex);
  }

  /**
   * @description Identify the selected milestone
   */
  getMilestone(index: number) {
    this.homeScreenDisplay = this.milestoneDataResult[index];
    this.getReactionsData(this.homeScreenDisplay);
    this.selectedIndex = index;
    this.certificationImagePath = this.homeScreenDisplay.carddetails.main_background;
    this.convertImage(this.homeScreenDisplay.carddetails.main_background);
    this.showLoader = false;
    const element: HTMLElement = document.getElementById('background-wrap') as HTMLElement;
    element.click();
  }

  /**
   * @description Get reactions of selected milestone
   */
  getReactionsData(selectedCardDetails) {
    const url = ApiUrlConstants.URL.getReactionData
      + '?userId=' + this.userData.email + '&user_milestone_ff_id=' + selectedCardDetails.milestone_id;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.reactionsCount = response.data.length;
        this.getFriendsFamily();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  getSelectedMilestone(index: number) {
    if (!JSON.parse(sessionStorage.getItem('relativesExist')) && !JSON.parse(sessionStorage.getItem('addLater'))) {
      this.showAddPopup = true;
    } else {
      const bubbleBackground = document.getElementById('background-wrap');
      if (0 === index % 2) {
        bubbleBackground.classList.add('green');
        bubbleBackground.classList.remove('yellow');
      } else {
        bubbleBackground.classList.remove('green');
        bubbleBackground.classList.add('yellow');
      }
      this.getMilestone(index);
    }
  }

  /**
   * @description Open the party popup
   */
  partyPopperClick() {
    if (!JSON.parse(sessionStorage.getItem('relativesExist')) && !JSON.parse(sessionStorage.getItem('addLater'))) {
      this.showAddPopup = true;
    } else {
      this.popupData = {
        showCertificatePopup: true,
        certificateDetails: this.homeScreenDisplay,
        action: 'partyPopper'
      };
      this.showCertificatePopup = true;
    }

  }

  /**
   * @description Close the party popup
   */
  certificatePopupEvent(event): void {
    if (event.action === 'partyPopper') {
      this.showCertificatePopup = event.showCertificatePopup;
    } else {
      this.showCertificatePopup = event;
    }
  }

  goToMomentsPage() {
    this.viewPage = 'momentsPage';
  }
  goToPreviewPage() {
    this.viewPage = 'previewPage';
  }
  goToHomePage() {
    this.viewPage = 'homePage';
  }

  ngAfterViewInit() {
    if (window.innerWidth > 768) {
      this.largeScreen = true;
    } else {
      this.largeScreen = false;
    }
  }

  /**
   * @description Get the screen size while resizing the screen
   * @param screenWidth width of current screen
   */
  screenResize(screenWidth) {
    this.getScreenSize(screenWidth);
    document.getElementById('right-arrow').classList.add('dim');
  }

  /**
   * @description Get the screen size and deside the number of milestones to be displayed in the timeline
   * @param screenWidth width of current screen
   */
  getScreenSize(screenWidth) {
    if (screenWidth < 768) {
      this.largeScreen = false;
      if (this.milestoneDataResult.length > 3) {
        this.sliceStart = this.milestoneDataResult.length;
        this.sliceEnd = this.sliceStart - 3;
        this.milestoneDataCopy = this.milestoneDataResult.slice(this.sliceEnd, this.sliceStart);
      }
    } else {
      if (this.milestoneDataResult.length > 10) {
        this.sliceStart = this.milestoneDataResult.length;
        this.sliceEnd = this.sliceStart - 10;
        this.milestoneDataCopy = this.milestoneDataResult.slice(this.sliceEnd, this.sliceStart);
      } else {
        this.milestoneDataCopy = this.milestoneDataResult;
      }
      this.largeScreen = true;
    }
    this.scrollShowHide();
  }

  /**
   * @description Timeline left arrow click functionality
   */
  leftArrowClick() {
    if (!JSON.parse(sessionStorage.getItem('relativesExist')) && !JSON.parse(sessionStorage.getItem('addLater'))) {
      this.showAddPopup = true;
    } else {
      const leftArrow = document.getElementById('left-arrow');
      const rightArrow = document.getElementById('right-arrow');
      if (this.milestoneDataResult[0].milestone_id !== this.milestoneDataCopy[0].milestone_id) {
        this.sliceStart = this.sliceStart - 1;
        this.sliceEnd = this.sliceEnd - 1;
        this.milestoneDataCopy = this.milestoneDataResult.slice(this.sliceEnd, this.sliceStart);
        rightArrow.classList.remove('dim');
        if (this.milestoneDataResult[0].milestone_id === this.milestoneDataCopy[0].milestone_id) {
          leftArrow.classList.add('dim');
        } else {
          leftArrow.classList.remove('dim');
        }
        this.scrollShowHide();
      } else {
        leftArrow.classList.add('dim');
      }
    }
  }

  /**
   * @description Timeline right arrow click functionality
   */
  rightArrowClick() {
    if (!JSON.parse(sessionStorage.getItem('relativesExist')) && !JSON.parse(sessionStorage.getItem('addLater'))) {
      this.showAddPopup = true;
    } else {
      const leftArrow = document.getElementById('left-arrow');
      const rightArrow = document.getElementById('right-arrow');
      if (this.milestoneDataResult[this.milestoneDataResult.length - 1].milestone_id !==
        this.milestoneDataCopy[this.milestoneDataCopy.length - 1].milestone_id) {
        this.sliceStart = this.sliceStart + 1;
        this.sliceEnd = this.sliceEnd + 1;
        this.milestoneDataCopy.length = 0;
        this.milestoneDataCopy = this.milestoneDataResult.slice(this.sliceEnd, this.sliceStart);
        leftArrow.classList.remove('dim');
        if (this.milestoneDataResult[this.milestoneDataResult.length - 1].milestone_id ===
          this.milestoneDataCopy[this.milestoneDataCopy.length - 1].milestone_id) {
          rightArrow.classList.add('dim');
        } else {
          rightArrow.classList.remove('dim');
        }
        this.scrollShowHide();
      } else {
        rightArrow.classList.add('dim');
      }
    }
  }

  /**
   * @description Add CSS class to show or hide timeline elements
   */
  scrollShowHide() {
    this.milestoneDataResult.forEach((i) => {
      if (this.milestoneDataCopy.includes(i)) {
        i.classes = '';
      } else {
        i.classes = 'hide';
      }
    });
  }

  /**
   * @description Fetch existing friends and family details in the F&F group
   */
  getFriendsFamily() {
    const url = ApiUrlConstants.URL.getRelativesListURL + '?emailId=' + this.userData.email;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.relativesList = response?.data?.friends_family;
        sessionStorage.setItem('relativesExist', JSON.stringify(this.relativesList?.length >= 1));
        sessionStorage.setItem('addLater', JSON.stringify(response?.data?.addLater));
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  clickPage(event) {
    // const url = ApiUrlConstants.URL.getRelativesListURL;
    // if (event.target.id === 'add-now' || event.target.id === 'add-later') {
    // } else {
    //   this.getFriendsFamily();
    // }
  }

  /**
   * @description Close the add friends and family popup
   */
  popupClose(event): void {
    this.showAddPopup = event;
    this.getFriendsFamily();
    const url = ApiUrlConstants.URL.updateUserURL;
    const requestBody = {
      'userId': this.userData.email,
      'addLater': true
    };
    this.apiService.ExecutePatch(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        sessionStorage.setItem('addLater', JSON.stringify(true));
        this.getData();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Show the add friends and family popup
   */
  goToAddFriendsAndFamily(event): void {
    this.showAddPopup = false;
    this.commonService.getFriendsAndFamilyCount.next(true);
    this.goToMomentsPage();
  }

  /**
   * @description Download user milestone certificate
   */
  downloadImage() {
    if (!JSON.parse(sessionStorage.getItem('relativesExist')) && !JSON.parse(sessionStorage.getItem('addLater'))) {
      this.showAddPopup = true;
    } else {
      this.auditSharing("downloads")
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) { // iOS = Iphone, Ipad, etc.
        this.createUserMilestoneCertificate('download-image');
      } else {
        // this.commonService.convertCertificateImage(this.homeScreenDisplay.carddetails.main_background)
        //   .subscribe(response => {
        //     const imageBlob = response;
        //     const reader = new FileReader();
        //     reader.readAsDataURL(imageBlob);
        //     reader.onloadend = () => {
        //       this.certificationImagePath = reader.result;
        //       if (!!this.certificationImagePath) {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.canvas.nativeElement.src = canvas.toDataURL();
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = 'certificate.png';
          this.downloadLink.nativeElement.click();
        });
        //     }
        //   };
        // });
      }
    }
  }

  /**
   * @description Create User Milestones Certificate
   */
  createUserMilestoneCertificate(action) {
    this.showLoader = true;
    this.milestoneData = JSON.parse(sessionStorage.getItem('milestoneData'));
    this.milestoneData.milestones.forEach((item) => {
      if (this.homeScreenDisplay.milestone_id === item.milestone_id) {
        this.cardBg = item.carddetails.main_background;
      }
    });
    const url = ApiUrlConstants.URL.createUserMilestoneCertPath;
    const requestBody = {
      'studentName': this.homeScreenDisplay.student_name,
      'cardDescription': this.homeScreenDisplay.card_description,
      'userId': this.homeScreenDisplay.student_email,
      'certificateBackground': this.cardBg,
      'milestone_id': this.homeScreenDisplay.milestone_id
    };
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        this.homeScreenDisplay.cert_path = response.data;
        if (action === 'download-image') {
          this.downloadInIos();
        } else {
          this.socialShareImg(action);
        }
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description User Milestones Certificate download functionality in ios devices.
   */
  downloadInIos() {
    this.commonService.convertCertificateImage(this.homeScreenDisplay.cert_path)
      .subscribe(response => {
        const imageBlob = response;
        FileSaver.saveAs(imageBlob, 'certificate.png');
      });
  }

  /**
   * @description Facebook and linkedIn share
   * @param action To identify whether facebook button or linkedIn button is clicked
   */
  socialShareAction(action) {
    this.disableSocialShare = true;
    if (this.homeScreenDisplay.cert_path === null) {  // To check milestone certificate path is null
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        this.createUserMilestoneCertificate(action);
      } else {
        this.blobImg(action);
      }
    } else {
      this.socialShareImg(action);
    }
  }

  blobImg(action) {
    this.commonService.convertCertificateImage(this.homeScreenDisplay.carddetails.main_background)
      .subscribe(response => {  // To convert certificate background image to blob
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.homeScreenDisplay.cert_path = reader.result;
          if (!!this.homeScreenDisplay.cert_path) {
            this.canvasImg(action);
          }
        };
      });
  }

  canvasImg(action) {
    html2canvas(this.screen.nativeElement).then(canvas => { // To convert certificate card html to canvas image
      this.canvas.nativeElement.src = canvas.toDataURL();
      const certImg = canvas.toDataURL('image/jpeg');
      this.firestoreUploadImg(action, certImg);
    });
  }

  firestoreUploadImg(action, certImg) {
    const dateNow = Date.now();
    const storage = firebase.storage(); // To upload canvas certificate image to firestore
    const storageRef = storage.ref();
    const imagesRef = storageRef.child(`userMilestoneCertificates/${this.homeScreenDisplay.student_email}/${dateNow}.jpeg`);
    imagesRef.putString(certImg, 'data_url')
      .then((snapshot) => { // To get download url of uploaded certificate image from firestore
        snapshot.ref.getDownloadURL().then((url) => {
          this.homeScreenDisplay.cert_path = url;
          this.updateCertImg(url);
          this.socialShareImg(action);
        });
      }).catch((error) => {
        this.showLoader = false;
        this.toastr.error('Please try again later', 'Some error has occured');
      });
  }

  updateCertImg(url) {    // API integration to upload certificate image to milestone collection in firestore when certificate path is null
    const apiUrl = ApiUrlConstants.URL.updateUserMilestoneCertPath + '?userId=' + this.homeScreenDisplay.student_email;
    const requestBody = {
      'milestone_id': this.homeScreenDisplay.milestone_id,
      'cert_path': url
    };
    this.apiService.ExecutePatch(apiUrl, requestBody, (response) => {
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  socialShareImg(action) {
    this.disableSocialShare = false;
    const shareImg = this.homeScreenDisplay.cert_path;
    const altAttr = '';
    if (action === 'facebook-share') {  // To share certificate image in facebook
      window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareImg) + '&t='
      + encodeURIComponent(altAttr) + '&redirect_uri=http://www.facebook.com/', 'sharer');
      this.auditSharing("facebook")
    } else if (action === 'linkedIn-share') { // To share certificate image in linkedIn
      window.open('http://www.linkedin.com/shareArticle?mini=true&url='
        + encodeURIComponent(shareImg) + '&title=' + encodeURIComponent(altAttr), 'sharer');
        this.auditSharing('linkedin')
    }
  }

  auditSharing(type){
    const url = this.isRelative ? ApiUrlConstants.URL.socialShareAuditFF : ApiUrlConstants.URL.socialShareAudit;
    const requestBody = {
      'userId': this.homeScreenDisplay.student_email,
      'type': type,
      'milestone_id': this.homeScreenDisplay.milestone_id
    };

    this.apiService.ExecutePost(url, requestBody, (response) => {
      console.log(response)
      if (response.status === 'success') {
      }
    }, (error) => {
      console.error(error)
    });
  }

}

