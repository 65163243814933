import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) { }

  /**
   * @description check if the current route should be enabled based on the user authentication.
   * @returns info if the user is authenticated or not.
   */
  canActivate(): Observable<boolean> {

    return this.auth.authInfo
      .pipe(
        take(1),
        map(user => !!user),
        tap(signedIn => {
          if (!signedIn) {
            this.auth.login((response) => { });
          }
        })
      );
  }

}
