<div class="unsubscribe-wrapper">
    <div class="content">
        <img class="logo-img" [src]="logoImagePath" alt="image_logo" />
        <img *ngIf="!cancelUnsubscribe" class="unsubscribe-img" [src]="unsubscribePath" alt="image_umsubscribe" />
        <img *ngIf="cancelUnsubscribe" class="cancel-img" [src]="cancelUnsubscribePath" alt="image_umsubscribe" />
        <ng-container *ngIf="buttonLabel != 'Unsubscribed' && !cancelUnsubscribe">
            <h1>Unsubscribe</h1>
            <a>{{unsubscribeData?.email}}</a>
            <p>Please confirm that you would like to unsubscribe from all the future emails from
                <span>Walden Moments</span>.
            </p>
        </ng-container>
        <ng-container *ngIf="buttonLabel === 'Unsubscribed' && !cancelUnsubscribe">
            <h1>You Will Be Missed!</h1>
            <a>{{unsubscribeData?.email}}</a>
            <p>Thank you for being with us. We had a great time together at <span>Walden Moments</span>.</p>
        </ng-container>
        <ng-container *ngIf="cancelUnsubscribe">
            <h1>Thats Great!</h1>
            <a>{{unsubscribeData?.email}}</a>
            <p>We are really happy that you choose to stay with us. Hoping to celebrate more great
                <span>Walden Moments</span> with you!
            </p>
        </ng-container>
        <button *ngIf="!cancelUnsubscribe" class="unsubscribe-btn" (click)="unsubscribeEmail()"
            [ngClass]="{'disable-btn': buttonLabel === 'Unsubscribed'}">{{buttonLabel}}</button>
        <button *ngIf="buttonLabel != 'Unsubscribed' && !cancelUnsubscribe" class="cancel-btn"
            (click)="cancelUnsubscribe = true"> Cancel</button>
        <p *ngIf="buttonLabel === 'Unsubscribed' && unsubscribeData?.userType === 'STUDENT'" class="re-subscribe">
            Please visit the manage friends and family popup in your Walden moments to re-subscribe the emails</p>
    </div>
    <app-loader *ngIf="showLoader"></app-loader>
</div>