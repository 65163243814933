import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlConstants } from '../../constants/api-urls';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  imageDetails: Subject<any> = new Subject();
  getFriendsAndFamilyCount: BehaviorSubject<any> = new BehaviorSubject('null');
  milestoneInfo: BehaviorSubject<any> = new BehaviorSubject(true);

  constructor(
    private readonly http: HttpClient
  ) { }

  /* get user details from session storage */
  public getUserName() {
    if (sessionStorage.getItem('userName')) {
      return (sessionStorage.getItem('userName'));
    } else {
      return null;
    }
  }

  public getUserToken() {
    if (sessionStorage.getItem('token')) {
      return (sessionStorage.getItem('token'));
    } else {
      return null;
    }
  }

  public getFFidToken() {
    if (sessionStorage.getItem('FF_idToken')) {
      return (sessionStorage.getItem('FF_idToken'));
    } else {
      return null;
    }
  }

  public getProfileImage() {
    const url = ApiUrlConstants.URL.profileImageURL;
    const headers = { Authorization: 'Bearer ' + sessionStorage.getItem('access_token') };
    return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' });
  }

  public convertCertificateImage(imageURL) {
    return this.http.get<any>(imageURL, { responseType: 'blob' as 'json' });
  }

}
