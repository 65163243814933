import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable()
export class ApiService {
  bearerToken: string;
  constructor(
    private readonly http: HttpClient,
    private readonly common: CommonService
  ) { }


  /**
   * @description Function for setting the API headers
   */
  private getHeader() {
    if (!!JSON.parse(sessionStorage.getItem('isRelative')) || !!JSON.parse(sessionStorage.getItem('unsubscribe'))){
      this.bearerToken = this.common.getFFidToken();
    }else{
      this.bearerToken = this.common.getUserToken();
    }
    return {
      Authorization: 'Bearer ' + this.bearerToken,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
  }


  /**
   * @description Execute the GET method
   * @param apiUrl URL of the API to be called
   * @param successCallback successCallback success callback for the API call function
   * @param failureCallback failureCallback failure callback for the API call function
   */
  ExecuteGet(apiUrl, successCallBack, failureCallBack) {
    this.http.get(apiUrl, { headers: this.getHeader() }).subscribe((response) => {
      successCallBack(response);
    }, (error) => {
      failureCallBack(error);
    });
  }

  /**
   * @description Execute the POST method
   * @param apiUrl URL of the API to be called
   * @param successCallback successCallback success callback for the API call function
   * @param failureCallback failureCallback failure callback for the API call function
   */
  ExecutePost(apiUrl, requestBody, successCallBack, failureCallBack) {
    this.http.post(apiUrl, requestBody, { headers: this.getHeader() }).subscribe((response) => {
      successCallBack(response);
    }, (error) => {
      failureCallBack(error);
    });
  }

  /**
   * @description Execute the PUT method
   * @param apiUrl URL of the API to be called
   * @param successCallback successCallback succes callback for the API call function
   * @param failureCallback failureCallback failure callback for the API call function
   */
  ExecutePut(apiUrl, requestBody, successCallBack, failureCallBack) {
    this.http.put(apiUrl, requestBody, { headers: this.getHeader() }).subscribe((response) => {
      successCallBack(response);
    }, (error) => {
      failureCallBack(error);
    });
  }

  /**
   * @description Execute the PATCH method
   * @param apiUrl URL of the API to be called
   * @param successCallback successCallback succes callback for the API call function
   * @param failureCallback failureCallback failure callback for the API call function
   */
  ExecutePatch(apiUrl, requestBody, successCallBack, failureCallBack) {
    this.http.patch(apiUrl, requestBody, { headers: this.getHeader() }).subscribe((response) => {
      successCallBack(response);
    }, (error) => {
      failureCallBack(error);
    });
  }

  /**
   * @description Execute the DELETE method
   * @param apiUrl URL of the API to be called
   * @param successCallback successCallback succes callback for the API call function
   * @param failureCallback failureCallback failure callback for the API call function
   */
  ExecuteDelete(apiUrl, requestBody, successCallBack, failureCallBack) {
    this.http.delete(apiUrl, requestBody).subscribe((response) => {
      successCallBack(response);
    }, (error) => {
      failureCallBack(error);
    });
  }

}
