import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConstants } from 'src/app/shared/constants/api-urls';
import { UrlConstants } from 'src/app/shared/constants/image-urls';
import { ApiService } from 'src/app/shared/services/common/api.service';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-preview-wall',
  templateUrl: './preview-wall.component.html',
  styleUrls: ['./preview-wall.component.scss']
})
export class PreviewWallComponent implements OnInit {
  @Input() isRelative;
  @Output() goToMomentsPage = new EventEmitter();
  @Output() goToHomePage = new EventEmitter();

  showPublishPopup = false;
  showCertificatePopup = false;
  showContent = false;
  logoImagePath = UrlConstants.URL.logoURL;
  logoWhitePath = UrlConstants.URL.logoWhiteURL;
  leftImagePath = UrlConstants.URL.leftArrowURL;
  rightImagePath = UrlConstants.URL.rightArrowURL;
  backImagePath = UrlConstants.URL.backArrowURL;
  dotsImagePath = UrlConstants.URL.mobileDotsURL;
  facebookImagePath = UrlConstants.URL.facebookUrl;
  linkedinImagePath = UrlConstants.URL.linkedInURL;
  downloadImagePath = UrlConstants.URL.downloadURL;
  loveImagePath = UrlConstants.URL.loveURL;
  baloonImagePath = UrlConstants.URL.baloonURL;
  clapImagePath = UrlConstants.URL.clapURL;
  element;
  leftArrow;
  rightArrow;
  leftMobArrow;
  rightMobArrow;
  popupData;
  milestoneDataResult;
  userData;
  milestonesPublished: any[] = [];
  publishInfo;
  count;
  uid;
  selectedCardDetails;
  displayName;
  firstName;
  showLoader = false;
  backgroundSkin;
  previewWallBackground: any[] = [];
  reactionsCount: any;
  bgSkinClass;
  milestone: any[] = [];
  momentsNotChanged = false;
  themeNotChanged = false;
  userEmailFF: any;
  ffEmail;


  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (!this.isRelative) {
      this.userData = JSON.parse(sessionStorage.getItem('user_data'));
      this.uid = this.userData?.email;
    } else {
      this.uid = JSON.parse(sessionStorage.getItem('relative_id'));
    }
    this.getUserMilestoneData();
  }

  /**
   * @description Fetch milestone data of the logged in user
   */
  getUserMilestoneData() {
    this.showLoader = true;
    const url = this.isRelative ? (ApiUrlConstants.URL.getUserMilestoneDataFF + '?ffUid=' + this.uid)
      : (ApiUrlConstants.URL.getUserMilestoneData + '?userId=' + this.uid);
    // const url = ApiUrlConstants.URL.getUserMilestoneData + '?userId=' + this.uid;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        sessionStorage.setItem('milestoneData', JSON.stringify(response?.data));
        this.displayName = response?.data?.firstName + ' ' + response?.data?.lastName;
        sessionStorage.setItem('userName', this.displayName);
        this.firstName = response?.data?.firstName;
        this.milestoneDataResult = response?.data?.milestones;
        this.bgSkinClass = response?.data?.skin_class;
        this.userEmailFF = response?.data?.userId;
        this.getFameWallSkins();
        this.sortByAchievementDate();
        this.milestoneDataResult.forEach(value => {
          if (value.previewMode) {
            this.milestonesPublished.push(value);
          }
          value.card_title = value.card_description.replace(/(<([^>]+)>)/gi, '');
        });
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });

  }

  /**
   * @description Get Fame Wall Skins Settings
   */
  getFameWallSkins() {
    const url = this.isRelative ? (ApiUrlConstants.URL.getFameWallSkinsFF) : (ApiUrlConstants.URL.getFameWallSkins);
    // const url = ApiUrlConstants.URL.getFameWallSkins;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        const skinSettings = response.data.backgrounds;
        Object.keys(skinSettings).map((keyNames, index) => {
          const obj = {
            id: index,
            class: keyNames,
            path: skinSettings[keyNames],
          };
          this.previewWallBackground = [...this.previewWallBackground, obj];
        });
        this.setPreviewBg();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Sort milestones in the order of latest achievements
   */
  sortByAchievementDate(): void {
    this.milestoneDataResult.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());

    const isPublishedArray = [];
    for (const element of this.milestoneDataResult) {
      if (this.isRelative) {
        if (element.is_published) {
          isPublishedArray.push(element);
        }
      } else {
        if (element.previewMode) {
          isPublishedArray.push(element);
        }
      }
    }
    this.addRemoveClass(isPublishedArray);

    /**
     * @description Opening the latest card when F&F opens the link recieved via email for card update.
     */
    if (this.isRelative) {
      for (const list of this.milestoneDataResult) {
        if (list.previewMode) {
          this.viewCertificateClick(list);
          break;
        }
      }
    }
  }

  addRemoveClass(isPublishedArray) {
    if (isPublishedArray?.length >= 13) {
      document.getElementById('masonry-wrapper').className = '';
      document.getElementById('masonry-wrapper').className.replace('lessthan13', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan4', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan7', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan10', '');
    } else if (isPublishedArray?.length < 13 && isPublishedArray?.length >= 10) {
      document.getElementById('masonry-wrapper').className = 'lessthan13';
      document.getElementById('masonry-wrapper').className.replace('lessthan4', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan7', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan10', '');
    } else if (isPublishedArray?.length < 10 && isPublishedArray?.length >= 7) {
      document.getElementById('masonry-wrapper').className = 'lessthan10';
      document.getElementById('masonry-wrapper').className.replace('lessthan4', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan7', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan13', '');
    } else if (isPublishedArray?.length < 7 && isPublishedArray?.length >= 4) {
      document.getElementById('masonry-wrapper').className = 'lessthan7';
      document.getElementById('masonry-wrapper').className.replace('lessthan4', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan10', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan13', '');
    } else if (isPublishedArray?.length < 4) {
      document.getElementById('masonry-wrapper').className = 'lessthan4';
      document.getElementById('masonry-wrapper').className.replace('lessthan7', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan10', '');
      document.getElementById('masonry-wrapper').className.replace('lessthan13', '');
    }
  }

  /**
   * @description Set preview wall background theme
   */
  setPreviewBg() {
    this.previewWallBackground.forEach(element => {
      if (element.class === this.bgSkinClass) {
        this.backgroundSkin = element;
        this.count = element.id;
      }
    });
    if (!this.isRelative) {
      this.leftArrow = document.getElementById('left-arrow');
      this.rightArrow = document.getElementById('right-arrow');
      this.leftMobArrow = document.getElementById('mob-left-arrow');
      this.rightMobArrow = document.getElementById('mob-right-arrow');
      if (0 === this.count) {
        this.leftArrow.classList.add('dim');
        this.leftMobArrow.classList.add('dim');
      }
      if (this.count === this.previewWallBackground.length - 1) {
        this.rightArrow.classList.add('dim');
        this.rightMobArrow.classList.add('dim');
      }
    }
  }

  /**
   * @description Change preview wall background theme to next one on right arrow click
   */
  changeToRight() {
    this.showContent = false;
    if (this.count < this.previewWallBackground.length - 1) {
      this.count += 1;
      this.backgroundSkin = this.previewWallBackground[this.count];
      this.backgroundChange();
      this.leftArrow.classList.remove('dim');
      this.leftMobArrow.classList.remove('dim');
      if (this.count === this.previewWallBackground.length - 1) {
        this.rightArrow.classList.add('dim');
        this.rightMobArrow.classList.add('dim');
      }
    }
  }

  backgroundChange() {
    if (this.backgroundSkin.class !== this.bgSkinClass) {
      this.themeNotChanged = false;
    } else {
      this.themeNotChanged = true;
    }
  }

  /**
   * @description Change preview wall background theme to previous one on left arrow click
   */
  changeToLeft() {
    this.showContent = false;
    if (this.count > 0) {
      this.count -= 1;
      this.backgroundSkin = this.previewWallBackground[this.count];
      this.backgroundChange();
      this.rightArrow.classList.remove('dim');
      this.rightMobArrow.classList.remove('dim');
      if (0 === this.count) {
        this.leftArrow.classList.add('dim');
        this.leftMobArrow.classList.add('dim');
      }
    }
  }

  /**
   * @description Show the publish popup
   */
  goToPublishPopup() {
    this.showContent = false;
    this.showPublishPopup = true;
    this.publishInfo = {
      showPublishPopup: true,
      data: this.milestonesPublished,
      uid: this.uid,
      class: this.backgroundSkin.class,
      status: '',
      themeNotChanged: this.themeNotChanged
    };
  }

  publishPopupEvent(event): void {
    this.showPublishPopup = event.showPublishPopup;
    if (event.status === 'publish') {
      this.themeNotChanged = true;
      this.momentsNotChanged = true;
      this.getUserMilestoneData();
    }
  }

  /**
   * @description Convert the certificate background image into base64 to make it downloadable
   */
  convertImage(imageURL) {
    this.commonService.convertCertificateImage(imageURL)
      .subscribe(response => {
        const imageBlob = response;
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.selectedCardDetails.carddetails.main_background = reader.result;
        };
      });
  }

  /**
   * @description Get reactions of selected milestone
   */
  getReactionsData(selectedCardDetails) {
    const url = this.isRelative ? (ApiUrlConstants.URL.getReactionDataFF
      + '?userId=' + this.userEmailFF + '&user_milestone_ff_id=' + selectedCardDetails.milestone_id)
      : (ApiUrlConstants.URL.getReactionData
        + '?userId=' + this.userEmailFF + '&user_milestone_ff_id=' + selectedCardDetails.milestone_id);
    // const url = ApiUrlConstants.URL.getReactionData
    //     + '?userId=' + this.userEmailFF + '&user_milestone_ff_id=' + selectedCardDetails.milestone_id;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.reactionsCount = response.data.length;
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Show the certificate details
   */
  viewCertificateClick(selectedCardDetails) {
    this.selectedCardDetails = selectedCardDetails;
    this.convertImage(this.selectedCardDetails.carddetails.main_background);
    this.popupData = {
      showCertificatePopup: true,
      certificateDetails: this.selectedCardDetails,
      isRelative: this.isRelative,
      userEmailFF: this.userEmailFF
    };
    this.showCertificatePopup = true;
  }

  certificatePopupEvent(event): void {
    this.showCertificatePopup = event.showCertificatePopup;
  }

  /**
   * @description Go back to moments wall page
   */
  goBack() {
    this.goToMomentsPage.emit();
  }

  /**
   * @description Go back to home page
   */
  goToHome() {
    this.goToHomePage.emit();
  }

}
