import { environment } from '../../../environments/environment';

export class ApiUrlConstants {
  public static readonly baseUrl = environment.baseURL;

  public static readonly URL = {
    /* api url for creating user */
    createUserURL: `${ApiUrlConstants.baseUrl}v1/rest/user/createUser`,

    /* api url for updating user details*/
    updateUserURL: `${ApiUrlConstants.baseUrl}v1/rest/user/updateUser`,

    /* api url for getting profile image of user */
    profileImageURL: `https://graph.microsoft.com/v1.0/me/photo/$value`,

    /* api url to add Friends/Family to the list */
    addRelativesURL: `${ApiUrlConstants.baseUrl}v1/rest/user/addFriend`,

    /* api url to delete Friends/Family to the list */
    deleteRelativesURL: `${ApiUrlConstants.baseUrl}v1/rest/user/deleteFriend`,

    /* api url to getting User’s friends & family list from user */
    getRelativesListURL: `${ApiUrlConstants.baseUrl}v1/rest/user/getUserDetails`,

    /* api url to get Milestone data from the collection */
    getMilestoneData: `${ApiUrlConstants.baseUrl}v1/rest/milestone/getMilestone`,

    /* api url to get user Milestone data from the collection */
    getUserMilestoneData: `${ApiUrlConstants.baseUrl}v1/rest/milestone/getUserMilestone`,
    getUserMilestoneDataFF: `${ApiUrlConstants.baseUrl}v1/public/rest/milestone/getUserMilestone`,

    /* api url to create Reactions Data from the F&F */
    addReactionURL: `${ApiUrlConstants.baseUrl}v1/rest/reactions/addReactions`,
    addReactionURLFF: `${ApiUrlConstants.baseUrl}v1/public/rest/reactions/addReactions`,

    /* api url to get reaction data from the collection */
    getReactionData: `${ApiUrlConstants.baseUrl}v1/rest/reactions/getReactions`,
    getReactionDataFF: `${ApiUrlConstants.baseUrl}v1/public/rest/reactions/getReactions`,

    /* api url to update User Milestones Preview flags */
    updatePreviewFlags: `${ApiUrlConstants.baseUrl}v1/rest/milestone/userMilestone/preview`,

    /* api url to update User Milestones Pubilshed flags */
    updatePublishedFlags: `${ApiUrlConstants.baseUrl}v1/rest/milestone/userMilestone/publish`,

    /* api url to get Fame Wall Skins Settings */
    getFameWallSkins: `${ApiUrlConstants.baseUrl}v1/rest/settings/skins/famewall`,
    getFameWallSkinsFF: `${ApiUrlConstants.baseUrl}v1/public/rest/settings/skins/famewall`,

    /* api url to update User Milestones Certificate path */
    updateUserMilestoneCertPath: `${ApiUrlConstants.baseUrl}v1/rest/milestone/userMilestone/certificate`,
    updateUserMilestoneCertPathFF: `${ApiUrlConstants.baseUrl}v1/public/rest/milestone/userMilestone/certificate`,

    /* Create User Milestones Certificate & update certificate path */
    createUserMilestoneCertPath: `${ApiUrlConstants.baseUrl}v1/rest/milestone/userMilestone/certificate`,
    createUserMilestoneCertPathFF: `${ApiUrlConstants.baseUrl}v1/public/rest/milestone/userMilestone/certificate`,

    /* Create Custom Tokens for F&F */
    createCustomTokenFF: `${ApiUrlConstants.baseUrl}v1/public/rest/server/token`,

    /* Unsubscribe email */
    unsubscribeEmails: `${ApiUrlConstants.baseUrl}v1/public/rest/email/unsubscribe`,

    /* api url to report social media sharing*/
    socialShareAudit: `${ApiUrlConstants.baseUrl}v1/rest/milestone/userMilestone/share`,
    socialShareAuditFF: `${ApiUrlConstants.baseUrl}v1/public/rest/milestone/userMilestone/share`

  };
}

