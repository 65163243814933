import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UrlConstants } from '../constants/image-urls';
import { AuthenticationService } from '../services/common/authentication.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-no-milestones-popup',
  templateUrl: './no-milestones-popup.component.html',
  styleUrls: ['./no-milestones-popup.component.scss']
})
export class NoMilestonesPopupComponent implements OnInit {
  @Input() accessTokenReceived: any;
  @Input() currentPage: any;
  @Output() goToHomePage = new EventEmitter();
  userName;
  logoImagePath = UrlConstants.URL.logoURL;
  closeImagePath = UrlConstants.URL.closeIconURL;
  hamburgurImgPath = UrlConstants.URL.hamburgurURL;
  profileImage = UrlConstants.URL.defaultProfileURL;
  noMilestonesImage = UrlConstants.URL.noMilestonesURL;
  userData: any;
  constructor(
    private commonService: CommonService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.userName = this.commonService.getUserName();
    this.profileImage = JSON.parse(localStorage.getItem('user_image'));
  }
  /**
   * @description Logout current user
   */
  microsoftLogout() {
    this.auth.logout();
  }

}
