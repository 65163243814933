import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlConstants } from '../constants/image-urls';

@Component({
  selector: 'app-bag-full-popup',
  templateUrl: './bag-full-popup.component.html',
  styleUrls: ['./bag-full-popup.component.scss']
})
export class BagFullPopupComponent implements OnInit {
  @Input() popupInput;
  @Output() popupOutput = new EventEmitter();
  bagfullImagePath = UrlConstants.URL.bagFullURL;

  constructor() { }

  ngOnInit() {
  }

  public closeAction() {  // Close popup
    this.popupInput = false;
    this.popupOutput.emit(this.popupInput);
  }
}
