<div class="popup-overlay">
    <div class="manage-wrapper fade-in">
        <div class="manage-img-wrapper"><img [src]="manageImagePath" alt="main-img" /></div>
        <h3 class="title">It’s kind of Lonely here!</h3>
        <div class="sub-title">
            <h4>Add Friends & Family</h4>
            <p>
                Please go ahead and add your friend and family here, so that they can view your moments and celebrate
                them with you.
            </p>
        </div>
        <div class="popup-btns">
            <button class="manage-btn skip" (click)="closeAction()" type="submit">Add Later</button>
            <button class="manage-btn submit" type="submit" (click)="updateFriendsFamily()">Add Now</button>
        </div>
        <app-loader *ngIf="showLoader"></app-loader>
    </div>
</div>