import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApiUrlConstants } from '../constants/api-urls';
import { ApiService } from '../services/common/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UrlConstants } from '../constants/image-urls';
import { CommonService } from '../services/common/common.service';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-popup',
  templateUrl: './manage-popup.component.html',
  styleUrls: ['./manage-popup.component.scss']
})
export class ManagePopupComponent implements OnInit {
  @Input() popupInput;
  @Output() popupOutput = new EventEmitter();
  @Output() popupData;
  notificationForm: FormGroup;
  userData;
  emailExist = false;
  submitted = false;
  showLoader = false;
  manageImagePath = UrlConstants.URL.friendsFamilyURL;
  addImagePath = UrlConstants.URL.addURL;
  closeImagePath = UrlConstants.URL.closeURL;
  relativesList: any[] = [];
  emailRegex = '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.(net|com|edu|info|org|com.br|co.uk|co.in|fr)$';
  regex = new RegExp(this.emailRegex);

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private common: CommonService,
    private toastr: ToastrService,
  ) {
    this.notificationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,
      Validators.pattern(this.regex)]],
      nickName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      notification: [''],
    });
  }

  ngOnInit() {
    this.showLoader = true;
    this.userData = JSON.parse(sessionStorage.getItem('user_data'));
    this.getFriendsFamily();
  }

  /**
   * @description Fetch existing friends and family details in the F&F group
   */
  getFriendsFamily() {
    const url = ApiUrlConstants.URL.getRelativesListURL + '?emailId=' + this.userData?.email;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.relativesList = response.data.friends_family;
        this.notificationForm.patchValue({
          notification: response.data.show_notification
        });
        this.popupData = this.relativesList;
        this.showLoader = false;
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Add new friends and family to the F&F group
   */
  addFriendsFamily() {
    // const newElement = this.newEmailsAdded.some(item => item === this.emailId);
    this.submitted = true;
    if (this.notificationForm.valid) {
      this.showLoader = true;
      const formValue = this.notificationForm.value;
      const url = ApiUrlConstants.URL.addRelativesURL;
      const requestBody = {
        'userId': this.userData.email,
        'ff_uid': sessionStorage.getItem('ff_uid'),
        'friends_family': {
          'email': formValue.email,
          'name': formValue.nickName
        }
      };
      this.apiService.ExecutePost(url, requestBody, (response) => {
        if (response.Status === 'Success') {
          this.relativesList.push({ name: formValue.nickName, email: formValue.email });
          firebase.analytics().logEvent('add friends and family');
          this.showLoader = false;
          sessionStorage.setItem('relativesExist', JSON.stringify(this.relativesList?.length >= 1));
          this.notificationForm.controls.nickName.reset();
          this.notificationForm.controls.email.reset();
        }
      }, (error) => {
        this.showLoader = false;
        this.toastr.error('Please try again later', 'Some error has occured');
      });
    }
  }

  /**
   * @description Remove existing friends and family from the F&F group
   */
  deleteFriendsFamily(element) {
    this.showLoader = true;
    const url = ApiUrlConstants.URL.deleteRelativesURL;
    const requestBody = {
      'userId': this.userData.email,
      'friends_family': {
        'email': element.email
      }
    };
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.common.getUserToken(),
        'Content-Type': 'application/json',
      }),
      body: requestBody
    };
    this.apiService.ExecuteDelete(url, requestOptions, (response) => {
      if (response.Status === 'Success') {
        this.relativesList.splice(this.relativesList.indexOf(element), 1);
        this.showLoader = false;
        sessionStorage.setItem('relativesExist', JSON.stringify(this.relativesList?.length >= 1));
        firebase.analytics().logEvent('delete friends and family');
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Notification for friends and family
   */
  updateFriendsFamily(event) {
    this.showLoader = true;
    const url = ApiUrlConstants.URL.updateUserURL;
    const requestBody = {
      'userId': this.userData.email,
      'show_notification': event.target.checked
    };
    this.apiService.ExecutePatch(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Check newly added email already exist in the F&F group
   */
  checkEmailExist() {
    if (this.relativesList.some(({ email }) => email === this.notificationForm.value.email)) {
      this.emailExist = true;
    } else {
      this.emailExist = false;
    }
  }

  /**
   * @description Close manage friends and family popup
   */
  closeAction(): void {
    this.popupInput = false;
    this.popupOutput.emit(this.popupInput);
  }
}
