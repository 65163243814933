<div class="app-splash-screen" *ngIf="showSplash">
    <header class="header splash-leader">
        <div class="header-wrapper">
            <div class="logo-wrapper">
                <img [src]="logoImagePath" alt="logo_img" />
            </div>
        </div>
    </header>
    <div class="app-splash-inner fade-in">
        <div class="app-trophy"></div>
        <div class="app-heading">
            <h1> Celebrate your
                <strong>Walden</strong>
            </h1>
        </div>
        <div class="app-moments"></div>
        <div class="app-content">
            <p>Manage your moments to be shared with family and friends</p>
        </div>
    </div>
    <p class="disclaimer">
        WALDEN STUDENT MOMENTS IS A TEST PRODUCT.  Please note that Walden Student Moments is not final and is still undergoing development and testing before its final release.
    </p>
</div>