import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(
    private firestore: AngularFirestore,
  ) { }

  /**
   * @description get data from the firestore.
   */
  public getData() {
    return this.firestore.collection('Student').doc('PDarBj8xbXqhdTIIov66')
      .valueChanges();
  }

}
