import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlConstants } from '../constants/image-urls';

@Component({
  selector: 'app-add-friendsandfamily-popup',
  templateUrl: './add-friendsandfamily-popup.component.html',
  styleUrls: ['./add-friendsandfamily-popup.component.scss']
})
export class AddFriendsandfamilyPopupComponent implements OnInit {
  @Input() popupClose;
  @Output() popupOutput = new EventEmitter();
  @Output() goToAddFriendsAndFamily = new EventEmitter();

  showLoader = false;
  closeImagePath = UrlConstants.URL.closeURL;
  manageImagePath = UrlConstants.URL.addFandFURL;

  constructor() { }

  ngOnInit(): void {
  }

  closeAction() { // Close popup
    this.popupClose = false;
    this.popupOutput.emit(this.popupClose);
  }
  updateFriendsFamily() {
    this.goToAddFriendsAndFamily.emit(true);
  }
}
