import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiUrlConstants } from '../constants/api-urls';
import { UrlConstants } from '../constants/image-urls';
import { ApiService } from '../services/common/api.service';

@Component({
  selector: 'app-add-reaction-popup',
  templateUrl: './add-reaction-popup.component.html',
  styleUrls: ['./add-reaction-popup.component.scss']
})
export class AddReactionPopupComponent implements OnInit {

  @Input() popupInput;
  @Output() popupOutput = new EventEmitter();
  addReactionForm: FormGroup;
  addReactionImagePath = UrlConstants.URL.addReactionURL;
  warningImagePath = UrlConstants.URL.ffWarningURL;
  emailExist = true;
  showLoader = false;
  userData;
  disableSubmit = false;
  profanityError = false;
  invalidEmail = false;
  ffEmail;
  emailRegex = '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.(net|com|co\.in)$';
  regex = new RegExp(this.emailRegex);
  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(res => {
      this.ffEmail = res.email;
    });
    this.addReactionForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [this.ffEmail, [Validators.required, Validators.email,
      Validators.pattern(this.regex)]]
    });
  }

  ngOnInit() {
    this.userData = JSON.parse(sessionStorage.getItem('user_data'));
  }

  cancelAction() {
    this.popupInput.showAddReactionPopup = false;
    this.popupInput.action = '';
    this.popupOutput.emit(this.popupInput);
  }

  submitAction() {
    this.popupInput.showAddReactionPopup = false;
    this.popupInput.action = 'addName';
    this.popupOutput.emit(this.popupInput);
  }

  checkFormValid() {
    if (this.addReactionForm.controls.email.status === 'VALID' || this.addReactionForm.controls.name.status === 'VALID') {
      this.disableSubmit = false;
      this.profanityError = false;
      this.invalidEmail = false;
    }
  }

  /**
   * @description Add reactions
   */
  addReactions() {
    this.disableSubmit = true;
    this.showLoader = true;
    const url = ApiUrlConstants.URL.addReactionURLFF;
    // const url = ApiUrlConstants.URL.addReactionURL;
    const requestBody = {
      'name': this.addReactionForm.controls.name.value ? this.addReactionForm.controls.name.value : null,
      'userId': this.popupInput.userEmailFF,
      'emailId': this.addReactionForm.controls.email.value,
      'reactionType': this.popupInput.reactionType,
      'user_milestone_ff_id': this.popupInput.milestone_id,
      'current_time': new Date().toISOString()
    };
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.emailExist = true;
        this.showLoader = false;
        this.submitAction();
      } else {
        this.showLoader = false;
      }
    }, (error) => {
      this.showLoader = false;
      if (error.error.errors[0].code === 'EMAIL_ABSENT_IN_FF_LIST') {
        this.emailExist = false;
      } else if (error.error.errors[0].code === 'PROFANITY_CHECK_FAILED') {
        this.profanityError = true;
      } else if (error.error.errors[0].code === 'RQST_PARAMS_MISSING') {
        this.invalidEmail = true;
      }
    });
  }

}
