<section class="section-wrapper">
    <div class="previewwall-wrapper" id="preview-wall" [style.backgroundImage]="'url('+ backgroundSkin?.path +')'">
        <div class="preview-header" [ngStyle]="{'justify-content': isRelative ? 'center' : '' }">
            <div class="arrow-wrapper header-item" [ngStyle]="{'display': isRelative ? 'none' : '' }">
                <div class="arrow back-arrow preview-arrow" tabindex="1" (click)="goBack()">
                    <img [src]="backImagePath" alt="left_img1" />
                </div>
            </div>
            <div class="logo-wrapper header-item" (click)="!isRelative? goToHome() : '' " id="home-btn" tabindex="1"
                [ngStyle]="{'cursor': isRelative ? 'context-menu' : '' }">
                <img *ngIf="backgroundSkin?.class != 'hotelwall'" [src]="logoImagePath" alt="logo_img1" />
                <img *ngIf="backgroundSkin?.class === 'hotelwall'" [src]="logoWhitePath" alt="logowhite_img1" />
            </div>
            <div class="arrow-wrapper header-item large-device" [ngStyle]="{'display': isRelative ? 'none' : '' }">
                <button class="publish-btn" (click)="goToPublishPopup()" type="submit">Publish to Friends &
                    Family</button>
                <div class="popover_wrapper">
                    <div class="arrow preview-arrow" id="left-arrow" (click)="changeToLeft()" tabindex="1">
                        <img [src]="leftImagePath" alt="left_img2" />
                    </div>
                    <div class="popover_content down-item">
                        Previous Theme
                    </div>
                </div>
                <div class="popover_wrapper">
                    <div class="arrow right-arrow preview-arrow" id="right-arrow" (click)="changeToRight()"
                        tabindex="1">
                        <img [src]="rightImagePath" alt="right_img1" />
                    </div>
                    <div class="popover_content down-item">
                        Next Theme
                    </div>
                </div>
            </div>
            <div class="header-item dots-container mobile-device" [ngStyle]="{'display': isRelative ? 'none' : '' }">
                <div class="popover_wrapper">
                    <div class="three-dots" (click)="showContent = !showContent" tabindex="1">
                        <img [src]="dotsImagePath" alt="preview_expand" />
                    </div>
                    <ul class="popover_content" [ngClass]='{ "hideDotsContent":!showContent}'>
                        <li id="mob-left-arrow" (click)="changeToLeft()">
                            <div class="arrow">
                                <img [src]="leftImagePath" alt="left_img3" />
                            </div> Previous Theme
                        </li>
                        <li id="mob-right-arrow" (click)="changeToRight()">Next Theme
                            <div class="arrow">
                                <img [src]="rightImagePath" alt="right_img2" />
                            </div>
                        </li>
                        <li>
                            <button class="publish-btn" (click)="goToPublishPopup()" type="submit">Publish to friends &
                                Family</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <h1 class="title" [ngClass]='{ "white-text": backgroundSkin?.class === "hotelwall"}'>
            <span [ngStyle]="{'display': !isRelative ? 'none' : '' }">{{firstName}}'s</span>
            <span [ngStyle]="{'display': isRelative ? 'none' : '' }">My</span>
            Walden Moments
        </h1>
        <div id="masonry-wrapper">
            <div class="masonry" id="preview-wall-card">
                <ng-container *ngFor="let list of milestoneDataResult; let i=index" tabindex="1">
                    <div *ngIf="(isRelative && list.is_published) || (!isRelative && list.previewMode)"
                        class="masonry-item tooltip-wrapper fade-in-down" (mouseenter)="getReactionsData(list)"
                        (click)="viewCertificateClick(list)" [ngClass]='{
                    "hide-item": isRelative ? !list.is_published : !list.previewMode,
                    "yellow-border": backgroundSkin?.class === "hotelwall"}' tabindex="{{i}}">
                        <img *ngIf="list.cert_path != null" class="preview-img" [src]='list.cert_path'
                            alt="milestone-img{{list?.milestone_id}}" />
                        <div *ngIf="list.cert_path == null" class="preview-img null-certpath"
                            [style.backgroundImage]="'url('+ list.carddetails.main_background +')'">
                            <div class="logo-wrapper">
                                <img [src]="logoImagePath" alt="logo_img{{list?.milestone_id}" />
                            </div>
                            <div class="text-wrapper">
                                <p class="heading">Congrats <strong>{{displayName}}</strong> </p>
                                <div class="card-desc" [title]="list?.card_title" [innerHTML]="list?.card_description">
                                </div>
                            </div>
                            <div class="cert-footer">EDUCATION FOR GOOD</div>
                        </div>
                        <div class="tooltiptext preview-tooltip">
                            <div class="popover-certificate"
                                [style.backgroundImage]="'url('+ list.carddetails.main_background +')'">
                                <div class="logo-wrapper">
                                    <img [src]="logoImagePath" alt="logo_img2" />
                                </div>
                                <div class="text-wrapper">
                                    <p class="heading">Congrats <strong>{{displayName}}</strong> </p>
                                    <div class="card-desc" [title]="list?.card_title"
                                        [innerHTML]="list?.card_description"></div>
                                </div>
                                <div class="cert-footer">EDUCATION FOR GOOD</div>
                            </div>
                            <p class="term">{{list.milestone_title}}</p>
                            <p class="desc">{{list.milestone_desc}}</p>
                            <div class="icon-wrapper masonry-hover">
                                <ul class="reaction-icons" *ngIf="!isRelative">
                                    <li class="img-wrap">
                                        <img [src]="loveImagePath" alt="love_img" />
                                    </li>
                                    <li class="img-wrap">
                                        <img [src]="baloonImagePath" alt="baloon_img" />
                                    </li>
                                    <li class="img-wrap">
                                        <img [src]="clapImagePath" alt="clap_img" />
                                    </li>
                                    <li>{{reactionsCount}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <app-loader *ngIf="showLoader"></app-loader>
    </div>
    <app-footer></app-footer>
</section>
<app-publish-popup *ngIf="showPublishPopup" [popupInput]="publishInfo" (popupOutput)="publishPopupEvent($event)">
</app-publish-popup>
<app-view-certificate-popup *ngIf="showCertificatePopup" [popupInput]="popupData"
    (popupOutput)="certificatePopupEvent($event)">
</app-view-certificate-popup>