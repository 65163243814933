<div class="popup-overlay">
    <div class="popup-wrapper publish-wrapper fade-in">
        <span class="close-img-wrapper" id="popup-close" (click)="cancelAction()" tabindex="1">
            <img [src]="closeImagePath" alt="close-img" />
        </span>
        <div class="main-img-wrapper"><img [src]="publishImagePath" alt="main-img" /></div>
        <h1>Publish to Friends & Family</h1>
        <p>Are you sure you want to publish the current moments wall to friends and family? </p>
        <div class="popup-btns">
            <button class="manage-btn skip" type="submit" (click)="cancelAction()">Cancel</button>
            <button class="manage-btn submit" type="submit" (click)="publishAction()">Publish</button>
        </div>
        <app-loader *ngIf="showLoader"></app-loader>
    </div>
</div>