<div class="popup-overlay">
    <div class="popup-wrapper entername-wrapper fade-in">
        <form [formGroup]="addReactionForm">
            <div class="main-img-wrapper"><img [src]="addReactionImagePath" alt="main-img" /></div>
            <p class="heading">Enter your Email</p>
            <p class="sub-head">Let your friend know who reacted.</p>
            <p class="label" for="name">Enter your email below</p>
            <div class="form-element" [ngClass]="!emailExist ? 'dim' : ''">
                <input [disabled]="!emailExist" required formControlName="email" placeholder="Email" type="text"
                    (input)="checkFormValid()" (keydown.enter)="false" aria-label="emailLabel">
            </div>
            <p class="error"
                *ngIf="emailExist && addReactionForm.controls.email.errors && addReactionForm.controls.email.errors.pattern">
                Please provide a valid email address!
            </p>
            <div class="ff-warning" *ngIf="!emailExist">
                <img [src]="warningImagePath" alt="warning" />
                <p>We were not able to find your details, please enter a name below to react.</p>
            </div>
            <p class="label" for="name" *ngIf="!emailExist">Enter your name below</p>
            <div class="form-element" *ngIf="!emailExist">
                <input formControlName="name" required placeholder="Name" type="text" (input)="checkFormValid()"
                    (keydown.enter)="false" aria-label="nameLabel">
            </div>
            <p class="error" *ngIf="profanityError">Bad words present!</p>
            <div class="popup-btns">
                <button class="manage-btn skip" type="submit" (click)="cancelAction()">Cancel</button>
                <button class="manage-btn submit" type="submit"
                    [disabled]="disableSubmit || (emailExist && addReactionForm.controls.email.status == 'INVALID') || (!emailExist && addReactionForm.controls.name.status == 'INVALID')"
                    (click)="addReactions()">Submit</button>
            </div>
        </form>
    </div>
    <app-loader *ngIf="showLoader"></app-loader>
</div>