<header class="header home-header">
    <div class="header-wrapper">
        <span class="hamburger-menu" (click)="openNav()" tabindex="1">
            <img [src]="hamburgurImgPath" alt="hamburgur_menu" />
        </span>
        <div class="logo-wrapper" (click)="goToHome()" id="logo-btn" tabindex="1">
            <img [src]="logoImagePath" alt="logo_img1" />
        </div>
        <button *ngIf="currentPage == 'momentsPage'" type="submit" (click)="showManagePopup=true"
            class="moments-btn manage-mob" id="manageFnF">
            <img [src]="manageImagePath" alt="manage_img" />
        </button>

        <div class="navbar-wrapper">
            <div class="header-navbar">
                <div class="list" (click)="goToMomentsWall()">
                    <button class="header-btn famewall-btn" id="share-moments" type="submit">Share Moments</button>
                </div>
                <div class="list">
                    <div class="profile-section">
                        <div class="profile-wrapper">
                            <img [src]="profileImage" alt="profile_img" />
                        </div>
                        <div class="profile">
                            <p class="user-name">{{userName}}</p>
                            <p class="logout"><span (click)="microsoftLogout()" tabindex="1">Log out</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="sidenav-wrapper" class="sidenav">
            <ul class="header-navbar">
                <span class="closebtn" (click)="closeNav()" tabindex="1">
                    <img [src]="closeImagePath" alt="close-img" />
                </span>
                <li>
                    <div class="logo-wrapper">
                        <img [src]="logoImagePath" alt="logo_img3" />
                    </div>
                </li>
                <li (click)="goToHome()" [ngClass]="{'active':currentPage == 'homePage'}">
                    <button type="submit">Home</button>
                </li>
                <li (click)="goToMomentsWall()" [ngClass]="{'active':currentPage == 'momentsPage'}">
                    <button class="header-btn famewall-btn" type="submit">Share Moments</button>
                </li>
                <li>
                    <div class="profile-section">
                        <div class="profile-wrapper">
                            <img [src]="profileImage" alt="logo_img4" />
                        </div>
                        <div class="profile-name">
                            <p class="user-name">{{userName}}</p>
                            <p class="logout"><span (click)="microsoftLogout()" tabindex="1">Log out</span></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</header>
<app-manage-popup *ngIf="showManagePopup" [popupInput]="showManagePopup" (popupOutput)="managePopupEvent($event)">
</app-manage-popup>