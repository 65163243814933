export const environment = {
  production: true,
  baseURL:
    'https://us-central1-app-waldenmoments-prod.cloudfunctions.net/walden_kse/',
  imageBaseURL:
    'https://firebasestorage.googleapis.com/v0/b/app-waldenmoments-prod.appspot.com/o/',
  domainHint: 'waldenu.edu',
  authService: 'microsoft.com',
  tenandId: '7e53ec4a-d325-4228-9e0e-a55a6b8892d5',
  firebase: {
    apiKey: 'AIzaSyAidO_AqLZT7VEY7ZohfgSFGkP_InM5yQA',
    authDomain: 'app-waldenmoments-prod.firebaseapp.com',
    projectId: 'app-waldenmoments-prod',
    storageBucket: 'app-waldenmoments-prod.appspot.com',
    messagingSenderId: '803935548118',
    appId: '1:803935548118:web:6eda3adb62c8cfc0dc8f77',
    measurementId: 'G-JFLHDTB7QL',
  },
};
