<div class="wrapper" *ngIf="viewPage == 'homePage' && userData" (click)="clickPage($event)">
    <app-header [accessTokenReceived]="accessTokenReceived" [currentPage]="'homePage'"
        (goToMomentsPage)="goToMomentsPage()"></app-header>
    <div class="landing-section">
        <div class="left-section slide-in-up">
            <h1><strong>{{homeScreenDisplay?.milestone_title}}</strong></h1>
            <p>{{homeScreenDisplay?.milestone_desc}}</p>
            <span>{{homeScreenDisplay?.created_date | date: 'd MMMM y | h:mm a'}}</span>

            <div class="icon-wrapper">
                <div class="share-btns">
                    <button class="social-share" (click)="socialShareAction('facebook-share')"
                        [ngClass]="disableSocialShare ? 'disable-share' : ''">
                        <img [src]="facebookImagePath" alt="fb_img" /></button>
                    <button class="social-share" (click)="socialShareAction('linkedIn-share')"
                        [ngClass]="disableSocialShare ? 'disable-share' : ''">
                        <img [src]="linkedinImagePath" alt="li_img" /></button>
                    <div class="loader-wrapper" *ngIf="disableSocialShare">
                        <div class="loader"></div>
                    </div>
                </div>
                <div class="img-wrap" id="download-item" (click)="downloadImage()" tabindex="1">
                    <img [src]="downloadImagePath" alt="download_img" />
                </div>
            </div>
        </div>
        <div class="right-section">
            <div class="right-wrapper">
                <div #screen class="right-content" [style.backgroundImage]="'url('+ certificationImagePath +')'">
                    <div class="logo-wrapper"> <img [src]="logoPngImagePath" alt="logo_img" /></div>
                    <div class="text-wrapper">
                        <h1>Congrats <strong>{{userData.displayName}}</strong> </h1>
                        <div class="card-desc" [innerHTML]="homeScreenDisplay?.card_description"></div>
                    </div>
                    <div class="slanting-box-wrapper">
                        <div class="tile"><span>Continue your journey</span></div>
                        <div class="tile"><span>Aim higher</span></div>
                        <div class="tile"><span>Increase your potential</span></div>
                    </div>
                    <div class="cert-footer">EDUCATION FOR GOOD</div>
                </div>
                <div id="congrats-popup" class="congrats" (click)="partyPopperClick()" tabindex="1">
                    <div class="congrats-img">
                        <img [src]="congratsImagePath" alt="congrats_img" />
                    </div>
                    <div class="count">{{reactionsCount}}</div>
                </div>
                <div id="download">
                    <img #canvas>
                    <a #downloadLink></a>
                </div>
            </div>
        </div>
        <div id="background-wrap">
            <div class="bubble x1"></div>
            <div class="bubble x2"></div>
            <div class="bubble x3"></div>
        </div>
    </div>
    <div class="flex-parent" (window:resize)="screenResize($event.target.innerWidth)">
        <div class="timeline-arrow-wrapper dim" (click)="leftArrowClick()" id="left-arrow" tabindex="1"
            [ngClass]="{'dim': milestoneDataResult?.length <= 3 || (largeScreen && milestoneDataResult?.length <= 10)}">
            <span><img [src]='leftImagePath' alt="left-arrow-img" /></span>
        </div>
        <ol class="input-flex-container" id="timeline">
            <li class="input {{list?.classes}}" *ngFor="let list of milestoneDataResult; let i=index"
                (click)="getSelectedMilestone(i)" [class.active]="selectedIndex === i"
                (mouseover)="list.mouseHover=true" (mouseout)="list.mouseHover=false">
                <span>
                    <div class="tooltip-wrapper">
                        <div class="image-wrapper milestone-item" tabindex="{{i}}">
                            <div class="image">
                                <img *ngIf="!list?.mouseHover && selectedIndex != i"
                                    [src]='list?.carddetails?.milestone_icon'
                                    alt="milestone-icon{{list?.milestone_id}}" />
                                <img *ngIf="list?.mouseHover || selectedIndex === i"
                                    [src]='list?.carddetails?.milestone_icon_active'
                                    alt="milestone-icon{{list?.milestone_id}}" />
                            </div>
                        </div>
                        <span class="tooltiptext">{{list?.milestone_title}}</span>
                    </div>
                </span>
            </li>
        </ol>
        <div class="timeline-arrow-wrapper dim" (click)="rightArrowClick()" id="right-arrow" tabindex="1">
            <span><img [src]='rightImagePath' alt="right-arrow-img" /></span>
        </div>
    </div>
    <p class="disclaimer">
        WALDEN STUDENT MOMENTS IS A TEST PRODUCT.  Please note that Walden Student Moments is not final and is still undergoing development and testing before its final release.
    </p>
    <app-loader *ngIf="showLoader"></app-loader>
</div>
<app-splash-screen *ngIf="showSplash"></app-splash-screen>
<app-view-certificate-popup *ngIf="showCertificatePopup" [popupInput]="popupData"
    (popupOutput)="certificatePopupEvent($event)">
</app-view-certificate-popup>

<app-moments-wall *ngIf="viewPage == 'momentsPage'" (goToHomePage)="goToHomePage()"
    (goToPreviewPage)="goToPreviewPage()"></app-moments-wall>
<app-preview-wall *ngIf="viewPage == 'previewPage'" [isRelative]="isRelative" (goToHomePage)="goToHomePage()"
    (goToMomentsPage)="goToMomentsPage()"></app-preview-wall>
<app-add-friendsandfamily-popup (goToAddFriendsAndFamily)="goToAddFriendsAndFamily($event)"
    (popupOutput)="popupClose($event)" *ngIf="showAddPopup"></app-add-friendsandfamily-popup>
<app-no-milestones-popup *ngIf="noMilestonesPopup" [accessTokenReceived]="accessTokenReceived" [currentPage]="'homePage'"></app-no-milestones-popup>
<app-unsubscribe-email *ngIf="unsubscribeEmails" [unsubscribePayload]="unsubscribeToken"></app-unsubscribe-email>