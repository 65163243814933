import { Component, Input, OnInit } from '@angular/core';
import { ApiUrlConstants } from '../constants/api-urls';
import { ApiService } from '../services/common/api.service';
import { ToastrService } from 'ngx-toastr';
import { UrlConstants } from '../constants/image-urls';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.scss'],
})
export class UnsubscribeEmailComponent implements OnInit {
  unsubscribeData;
  @Input() unsubscribePayload: any;
  buttonLabel = 'Yes, Unsubscribe';
  showLoader = false;
  cancelUnsubscribe = false;
  logoImagePath = UrlConstants.URL.logoURL;
  unsubscribePath = UrlConstants.URL.unsubscribeURL;
  cancelUnsubscribePath = UrlConstants.URL.cancelUnsubscribeURL;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
    ) {
    }

  ngOnInit(): void {
    this.getUnsubscribeHashInfo();
  }

  getUnsubscribeHashInfo(){
    this.showLoader = true;
    const url = ApiUrlConstants.URL.unsubscribeEmails + '/?payload=' + this.unsubscribePayload;
    this.apiService.ExecuteGet(url, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        this.unsubscribeData = response.data;
       }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  unsubscribeEmail(){
    this.showLoader = true;
    const requestBody = {
      'payload': this.unsubscribePayload
    };
    const url = ApiUrlConstants.URL.unsubscribeEmails;
    this.apiService.ExecutePost(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        this.buttonLabel = 'Unsubscribed';
        if (this.unsubscribeData?.userType === 'STUDENT'){
          firebase.analytics().logEvent('unsubscription: students');
        }else if (this.unsubscribeData?.userType === 'F_F_MEMBER'){
          firebase.analytics().logEvent('unsubscription: friends and family');
        }
       }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }
}
