<div class="popup-overlay no-milestones">
    <header class="header home-header">
        <div class="header-wrapper">
            <div class="logo-wrapper">
                <img [src]="logoImagePath" alt="logo_img1" />
            </div>
            <div class="navbar-wrapper">
                <div class="header-navbar">
                    <div class="list">
                        <div class="profile-section">
                            <div class="profile-wrapper">
                                <img [src]="profileImage" alt="profile_img" />
                            </div>
                            <div class="profile-tooltip">
                                <img [src]="profileImage" alt="profile_img" />
                                <span class="tooltiptext" (click)="microsoftLogout()">Log out</span>
                              </div>
                            <div class="profile">
                                <p class="user-name">{{userName}}</p>
                                <p class="logout"><span (click)="microsoftLogout()" tabindex="1">Log out</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="noMilestones-wrapper">
        <div class="img-wrapr">
            <img [src]="noMilestonesImage" alt="noMilestones_img" />
        </div>
        <h1>No milestones to display!</h1>
        <p>Please visit later when you have some milestone cards to celebrate with your Friends & Family.</p>
    </div>
</div>