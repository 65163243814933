import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConstants } from '../constants/api-urls';
import { UrlConstants } from '../constants/image-urls';
import { ApiService } from '../services/common/api.service';
import * as firebase from 'firebase/app';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-publish-popup',
  templateUrl: './publish-popup.component.html',
  styleUrls: ['./publish-popup.component.scss']
})
export class PublishPopupComponent implements OnInit {
  @Input() popupInput;
  @Output() popupOutput = new EventEmitter();
  publishImagePath = UrlConstants.URL.publishURL;
  userData;
  milestonesPublished: any[] = [];
  showLoader = false;
  closeImagePath = UrlConstants.URL.closeURL;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private common: CommonService
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(sessionStorage.getItem('user_data'));
    this.milestonesPublished = this.popupInput.data;
  }

  /**
   * @description Cancel button click
   */
  cancelAction(): void {
    this.popupInput.showPublishPopup = false;
    this.popupOutput.emit(this.popupInput);
  }

  /**
   * @description Publish button click
   */
  publishAction(): void {
    this.milestonesPublished.forEach((element) => {
      element.is_published = true;
    });
    this.updatePublishFlags();
    if (!this.popupInput.themeNotChanged) {
      this.updatefameWallSkin();
    }
    this.popupInput.showPublishPopup = false;
    this.popupInput.status = 'publish';
    this.popupOutput.emit(this.popupInput);
  }

  /**
   * @description update the publish flags
   */
  updatePublishFlags() {
    this.showLoader = true;
    const url = ApiUrlConstants.URL.updatePublishedFlags + '?userId=' + this.userData.email;
    const requestBody = {
      'milestones': this.milestonesPublished
    };
    this.apiService.ExecutePatch(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        firebase.analytics().logEvent('publish moments');
        this.showToasterSuccess();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }
  /**
   * @description Update preview wall background skin
   */
  updatefameWallSkin() {
    this.showLoader = true;
    const url = ApiUrlConstants.URL.updateUserURL;
    const requestBody = {
      'userId': this.popupInput.uid,
      "skin_class": this.popupInput.class
    };
    this.apiService.ExecutePatch(url, requestBody, (response) => {
      if (response.Status === 'Success') {
        this.showLoader = false;
        firebase.analytics().logEvent('update theme');
        this.showToasterSuccess();
      }
    }, (error) => {
      this.showLoader = false;
      this.toastr.error('Please try again later', 'Some error has occured');
    });
  }

  /**
   * @description Show toast message on publish success
   */
  showToasterSuccess() {
    this.common.milestoneInfo.next(true);
    this.toastr.success('The selected theme and the moments were published to friends & family successfully.', 'Published to Friends & Family Successfully!');
  }

}
